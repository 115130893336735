import { Component, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { InitiateProfileSharedService } from './initiate.profile.shared.service';
import { DemographicsService } from '../../core/services/demographics.service';
import cloneDeep from 'lodash/cloneDeep';
var $ = (<any>window).$;
import { SessionStorage } from '../../core/context/sessionstorage';
//import { ManageDemographicsComponent } from './model.popup.component/manage.demographics.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import * as Enumerable from 'linq';
import { ScopeSelectorComponent } from '../scope.selector.component/scope.selector.component';
import { HeaderComponent } from '../header.component/header.component';
import { SharedService } from '../../core/services/shared.service';
import { InitiateProfileComponent } from './initiate.profile.component';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './delivery.portfolio.component.html',
  providers: [TileService, InitiateProfileSharedService, DemographicsService, ScopeSelectorComponent, HeaderComponent]
})

export class DeliveryPortfolioComponent {
  @ViewChild('deliveryportfolioform') deliveryForm: NgForm;

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (this.deliveryForm.form.dirty) { return confirm('Changes you made may not be saved.'); }
    else { return true; }
  }

  constructor(private tileService: TileService, private modalService: BsModalService, private location: Location, private scopeSelectorComponent: ScopeSelectorComponent, private sessionStorage: SessionStorage, private initiateProfileSharedService: InitiateProfileSharedService, private demographicsService: DemographicsService, private sharedService: SharedService, private initiateProfileComponent: InitiateProfileComponent, private headerComponent: HeaderComponent) { }

  endToEndId: any;
  endToEndType: string;
  DisableSubgroupDropdown: boolean;
  CreatePage: boolean;
  E2eDemoGraphics: any;
  DeliveryPortfolioDetails: any;
  deliverysubgroup: any;
  IsValidated: boolean;
  ValidationMarketUnit: boolean;
  ExistingE2eDemoGraphics: any;
  LoadDirective: boolean;
  RoleAccess = this.sessionStorage.roleAccessCode;
  AvanadeGeoCountryLoad: any;
  IsOppInitNotCreated: boolean = false;
  RoleTypeCode = (this.sessionStorage.roleTypeCode == null || this.sessionStorage.roleTypeCode == undefined) ? false : (this.sessionStorage.roleTypeCode.indexOf('A') == 0) ? true : false;
  Role: string;
  path = this.location.path();
  modalRefManageDemographicPopup: BsModalRef;
  @Output() InitiateProfileTabSetup = new EventEmitter();
  isOpportunityCreator: boolean;
  isInitiativeCreator: boolean;
  IsOpportunityInitiativeCreator: boolean;


  ngOnInit() {
    this.initiateProfileSharedService.SetupProfileAndAdminPage(this);
    this.endToEndId = this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1 ? this.sessionStorage.createdE2EId : this.sessionStorage.endToEndId;

    this.LoadDeliveryPortfolioTab();
    setTimeout(() => {
      window.TooltipFunc();
    }, 0);

  }

  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }

  async saveDeliveryPortfolio(formvalid, form) {
    
    if (formvalid && $('#portfolio').find('.has-error').length < 1) {
      if (this.DeliveryPortfolioDetails.DeployRegionName == 2 && this.DeliveryPortfolioDetails.ManagementMarketUnitId == 7) {
        this.sharedService.openAlertPopup('Market Unit cannot be ‘Greater China’ when Deploy Region is ‘EU’. Please update the Market Unit to proceed.', window.scrollY, "Ok", false);
        return false;
      }
      if (this.DeliveryPortfolioDetails.IsAccentureCGDominant || this.DeliveryPortfolioDetails.IsAvanadeGUDominant) {
        if ((this.DeliveryPortfolioDetails.AvanadeGSAId == null || this.DeliveryPortfolioDetails.AvanadeGSAId == 'undefined') && (this.DeliveryPortfolioDetails.RbeId == 4 || this.DeliveryPortfolioDetails.CompanyId == 2)) {
          if (this.DeliveryPortfolioDetails.RbeId == 4 && this.DeliveryPortfolioDetails.AvanadeChannelId != 0) {
            this.sharedService.openAlertPopup("Please Fill Avanade GSA.", window.scrollY);
            return false;
          }
        }
        if (this.endToEndType != "C" && (this.DeliveryPortfolioDetails.AvanadeGSAId == null || this.DeliveryPortfolioDetails.AvanadeGSAId == 'undefined') && this.DeliveryPortfolioDetails.CompanyId == 2) {
          if (this.DeliveryPortfolioDetails.AvanadeChannelId != 0) {
            this.sharedService.openAlertPopup("Please Fill Avanade GSA.", window.scrollY);
            return false;
          }
        }


      }
      
      this.E2eDemoGraphics.EnterpriseId = this.sessionStorage.enterpriseId;
      if (this.path.indexOf('Opportunity') > -1 || this.path.indexOf('InternalInitiative') > -1) {
        this.E2eDemoGraphics.IsAdminTab = true;
      }
      this.E2eDemoGraphics.RoleTypeCode = this.RoleAccess;
      this.demographicsService.postE2EDemographics(this.E2eDemoGraphics).subscribe((response: any) => {
        if (response) {
          this.sharedService.openAlertPopup(response, window.scrollY);
        }
        else {
          this.sharedService.openAlertPopup("Saved Successfully", window.scrollY);
        }
        if (form != undefined) form.resetForm();
        this.LoadDeliveryPortfolioTab();
        this.initiateProfileComponent.LoadMandatoryDataAttrSet();
        //this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicsService);

      });
    }
    else {
      this.sharedService.openAlertPopup('Please review the highlighted fields and update accordingly.', window.scrollY);
      await this.focusErrorField();
    }

  }
  LoadDeliveryPortfolioTab() {
    this.DisableSubgroupDropdown = false;

    this.demographicsService.getE2EDemographics(this.endToEndId, this.endToEndType, "DeliveryPortfolio", false, this.CreatePage).subscribe((response: any) => {
      this.E2eDemoGraphics = response;
      this.DeliveryPortfolioDetails = response.DeliveryPortfolioDetails;

      //Management Market Unit - Dropdown binding
      if (this.DeliveryPortfolioDetails.ManagementMarketId != null || this.DeliveryPortfolioDetails.ManagementMarketId != undefined) {
        var marketUnitList = this.DeliveryPortfolioDetails.ManagementMarketUnits;
        var marketUnit = marketUnitList.filter((x: any) => x.ParentId == this.DeliveryPortfolioDetails.ManagementMarketId);
        this.DeliveryPortfolioDetails.MMU = marketUnit;
      }
      else {
        this.DeliveryPortfolioDetails.MMU = cloneDeep(this.DeliveryPortfolioDetails.ManagementMarketUnits);
      }

      this.ExistingE2eDemoGraphics = cloneDeep(response);
      this.deliverysubgroup = this.DeliveryPortfolioDetails.SelectedDeliverySubgroup;
      this.IsValidated = false;
      this.LoadDirective = true;
      $("[name='deliveryportfolioform']").find('button:first').focus();
      if (this.DeliveryPortfolioDetails.SelectedSubgroupCategory != null && this.DeliveryPortfolioDetails.SelectedDeliverySubgroup != null) {
        this.DisableSubgroupDropdown = true;
      }
      if (this.Role == "Admin - Accenture") {
        this.DeliveryPortfolioDetails.Companies = this.DeliveryPortfolioDetails.Companies.filter(x => x.Id != 2).toArray();
      }
      else if (this.Role == "Admin - Avanade") {
        this.DeliveryPortfolioDetails.Companies = this.DeliveryPortfolioDetails.Companies.filter(x => x.Id != 1).toArray();
      }
      this.ValidationMarketUnit = null;
      this.IsOppInitNotCreated = false;
      this.InitiateProfileTabSetup.emit({ IsOppInitNotCreated: this.IsOppInitNotCreated });
      if (this.endToEndType == "C" && this.DeliveryPortfolioDetails.RbeId != null && (this.DeliveryPortfolioDetails.RbeId == 4 || (this.DeliveryPortfolioDetails.CompanyId == 2 || this.DeliveryPortfolioDetails.CompanyId == 1))) {
        if (this.DeliveryPortfolioDetails.IsAccentureCGDominant || this.DeliveryPortfolioDetails.IsAvanadeGUDominant) {
          this.ValidationMarketUnit = true;
        }
        this.avanadeGeoUnitChange(this.DeliveryPortfolioDetails.AvanadeGeographicUnitId);
      }
      else if (this.endToEndType != "C" && this.DeliveryPortfolioDetails.CompanyId == 2) {
        if (this.DeliveryPortfolioDetails.IsAccentureCGDominant || this.DeliveryPortfolioDetails.IsAvanadeGUDominant) {
          this.ValidationMarketUnit = true;
        }
        this.avanadeGeoUnitChange(this.DeliveryPortfolioDetails.AvanadeGeographicUnitId);
      }
      else if (this.DeliveryPortfolioDetails.CompanyId == 2 || this.DeliveryPortfolioDetails.CompanyId == 1) {
        this.avanadeGeoUnitChange(this.DeliveryPortfolioDetails.AvanadeGeographicUnitId);
      }

      if (this.endToEndType == "I" && this.DeliveryPortfolioDetails.ManagementMarketUnitId != null && this.DeliveryPortfolioDetails.ManagementClientGroupId == null) {
        this.MarketUnitChange(this.DeliveryPortfolioDetails.ManagementMarketUnitId);
      }
    });
  }

  showIndustrySegment() {

    var industrylist = this.DeliveryPortfolioDetails.IndustrySegments;
    var selectedindustrysegment = this.DeliveryPortfolioDetails.IndustryInputs;
    var industrygriddata = Enumerable.from(industrylist).join(
      selectedindustrysegment, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();

    var involvedFactoriesList = this.DeliveryPortfolioDetails.InvolvedFactories;
    var selectedInvolvedFactory = this.DeliveryPortfolioDetails.InvolvedFactoryInputs;
    var gridData = Enumerable.from(involvedFactoriesList).join(
      selectedInvolvedFactory, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();

    //const initialState = { title: "Industry Segments", label: "Industry Segment", gridData: industrygriddata, masterData: this.DeliveryPortfolioDetails.IndustrySegments };
    //var modelRef = this.modalService.show(ManageDemographicsComponent, {
    //  initialState,
    //  backdrop: "static",
    //  keyboard: false
    //});
    //$(".modal-dialog").addClass('custom-modal');

    //modelRef.content.manageDemographicOutput.subscribe(result => {
    //  this.DeliveryPortfolioDetails.IndustrySegement = result.gridData.map(x => x.Name).join();
    //  this.DeliveryPortfolioDetails.IndustryInputs = result.gridData.map(({ Id }) => Id);
    //});

  }
  CompanyChange(Id) {
    this.initiateProfileSharedService.CompanyChange(Id, this);
  }

  ManagementMarkeChange(Id) {
    this.initiateProfileSharedService.ManagementMarkeChange(Id, this);
  }

  MarketUnitChange(Id) {
    this.initiateProfileSharedService.MarketUnitChange(Id, this);
  }

  ClientGroupChange() {
    this.initiateProfileSharedService.ClientGroupChange(this);
  }

  showInvolvedFactories() {
    this.initiateProfileSharedService.showInvolvedFactories(this);
  }


  SubgroupCategoryChange() {
    this.initiateProfileSharedService.SubgroupCategoryChange(this);
  }

  SubGroupChange() {
    this.initiateProfileSharedService.SubGroupChange(this);
  }

  avanadeChannelChange() {

    this.initiateProfileSharedService.avanadeChannelChange(this);

  }

  avanadeGeoUnitChange(Id, eventflag?) {
    this.initiateProfileSharedService.avanadeGeoUnitChange(Id, this, eventflag);
  }


  cancel(form) {
    if (form != undefined) {
      form.resetForm();
    }
    this.LoadDeliveryPortfolioTab();
  }

  focusErrorField() {
    return new Promise(done => {
      setTimeout(() => {
        $("[name='deliveryportfolioform']").find('.has-error')[0].focus();
      }, 0);
    });
  }

  trackByServiceGroupId(index: number, item: any): any {
    return item.ServiceGroupId;
  }
}

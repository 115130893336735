import { Component, HostListener, ElementRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorage } from '../../core/context/sessionstorage';
import { DemographicsService } from '../../core/services/demographics.service';
import cloneDeep from 'lodash/cloneDeep';
import { SharedService } from '../../core/services/shared.service';
import { DatePipe, Location } from '@angular/common';
import * as Enumerable from 'linq';
import { enumModuleAction, enumTile, enumFeatureAccess, enumProfileTab, enumSubmissionType } from '../../core/context/enum';
import { TileService } from '../../core/services/tile.service';
import { InitiateProfileComponent } from './initiate.profile.component';
var $ = (<any>window).$;
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ManageCustomerComponent } from './model.popup.component/manage.customer.component';
import { MultiContractDetailComponent } from './model.popup.component/multi.contract.details.component';
import { ScopeSelectorComponent } from '../scope.selector.component/scope.selector.component';
import { HeaderComponent } from '../header.component/header.component';

import { InitiateProfileSharedService } from './initiate.profile.shared.service';
import * as dayjs from 'dayjs';
import { ManageContractsComponent } from './model.popup.component/manage.contracts.component';
import { BCMPriorityDescriptionComponent } from './model.popup.component/bcm.priority.description.component/bcm.priority.description.component';
import { UnsavedChangesService } from '../../core/services/unsaved.changes.service';
import { BroadCastService } from '../../core/services/broadcast.service';
import { environment } from '../../../environments/environment';
var $ = (<any>window).$;
declare var window: any;
@Component({
  templateUrl: './profile.component.html',

  providers: [DemographicsService, DatePipe, TileService, SharedService, ScopeSelectorComponent, InitiateProfileSharedService, BsModalService, HeaderComponent]
})

export class ProfileComponent {

  @ViewChild('profileform') profileForm: NgForm;
  unsavedSubscription: Subscription;
  unsavedConfirm: boolean = false;
  SubmissionType = this.sessionStorage.selectedSubmissionType;
  dayjs: any = dayjs;
  DisableSubgroupDropdown: boolean;
  endToEndId = this.sessionStorage.endToEndId;
  endToEndType = this.sessionStorage.endToEndType;
  CreatePage: boolean;
  E2eDemoGraphics: any;
  DeliveryPortfolioDetails: any;
  ExistingE2eDemoGraphics: any
  OpportunityCode: string;
  OpportunityName: string;
  InitiativeName: string;
  Contract: string;
  StartDate: string;
  EndDate: string;
  Path = this.router.url;
  IsValidated: boolean;
  profileDetails: any;
  ExistingTransitionContractID: any;
  ExistingTransitionContractStatus: any;
  DisableSave = false;
  Role = this.sessionStorage.roleType;
  IsCompanyDisable = false;
  IsSIFlagDisable = false;
  ValidationMarketUnit: boolean;
  endDateValidation = { IsValidEndDate: true };
  RoleTypeCode = (this.sessionStorage.roleTypeCode == null || this.sessionStorage.roleTypeCode == undefined) ? false : (this.sessionStorage.roleTypeCode.indexOf('A') == 0) ? true : false;
  path = this.location.path();
  AvanadeGeoCountryLoad: any;
  IsDisableTestRecordIndicator = false;
  futureDateValidation = {
    IsFutureEndDate: true
  };
  IsOppInitNotCreated: boolean;
  TabChanges: boolean;
  clientGroup: any;
  RoleAccess = this.sessionStorage.roleAccessCode;
  IsAdmin = this.sharedService.isAdmin(this.sessionStorage.roleTypeCode);
  ProfileTabHighlight: boolean;
  keyContactTabHighlight: boolean;
  startdateform: any;
  enddateform: any;
  involvedfactoriesgriddata: any;
  isOpportunityCreator: boolean;
  isInitiativeCreator: boolean;
  IsOpportunityInitiativeCreator: boolean;
  isAssociateContractPopupShow: boolean;
  tempRDE: any;
  @Output() SetActiveTabSelection = new EventEmitter();
  @Output() ScopeSelecorVisible = new EventEmitter();
  @Output() RefreshScopeSelector = new EventEmitter();
  @Output() InitiateProfileTabSetup = new EventEmitter();
  modalRefManageDemographicPopup: BsModalRef;
  modalRefConfirmationPopup: BsModalRef;
  modalRefCustomerPopup: BsModalRef;
  DisplayName: any;
  TestRecordIndicator: any;
  SIFlag: any;
  IsProfileCompleted: boolean;
  selectedDeployRegionName: any;
  ProfileCompletionSubscription: Subscription;
  endDateValue: boolean = true;
  startDateValue: boolean = true;

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedConfirm && this.sessionStorage.endToEndUId) {
      if (this.profileForm.form.dirty) { return confirm('Changes you made may not be saved.'); }
      else { return true; }
    } else {
      return true;
    }
  }

  constructor(
    private demographicService: DemographicsService,
    private router: Router,
    private sessionStorage: SessionStorage,
    private sharedService: SharedService,
    private element: ElementRef,
    private datepipe: DatePipe,
    private location: Location,
    private initiateProfileComponent: InitiateProfileComponent,
    private tileService: TileService,
    private modalService: BsModalService,
    private scopeSelectorComponent: ScopeSelectorComponent,
    private initiateProfileSharedService: InitiateProfileSharedService,
    private unsavedChangesService: UnsavedChangesService,
    private activatedRoute: ActivatedRoute,
    private headerComponent: HeaderComponent,
    private broadCastService: BroadCastService

  ) {


    this.unsavedSubscription = this.unsavedChangesService.currentMessage.subscribe((data) => {
      if (data == "profile") {
        if (this.profileForm.form.dirty) {
          if (confirm("Changes you made may not be saved.")) {
            this.unsavedConfirm = true;
            this.sessionStorage.isUserAlert = false;
          } else {
            this.sessionStorage.isUserAlert = true;
          }
        }
        else {
          this.sessionStorage.isUserAlert = false;
        }
      }
    });
    this.ProfileCompletionSubscription = this.broadCastService.refreshProfileCompletion().subscribe((response) => {
      if (response != null) {
        this.profileCompletedStatus(response);
      }
    });
  }

  ngOnInit() {
    this.initiateProfileSharedService.SetupProfileAndAdminPage(this);
    if (!this.sessionStorage.endToEndUId && !this.IsOpportunityInitiativeCreator) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }
    this.endToEndId = this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1 ? this.sessionStorage.createdE2EId : this.sessionStorage.endToEndId;
    this.LoadProfileTab();
    this.IsProfileCompleted = this.sessionStorage.IsProfileCompleted;
    setTimeout(() => {
      window.TooltipFunc();
    }, 0);
  }
  ngAfterViewInit() {
    this.IsProfileCompleted = this.sessionStorage.IsProfileCompleted;
  }
  ngOnDestroy() {
    this.unsavedSubscription.unsubscribe();
    this.ProfileCompletionSubscription.unsubscribe();
  }

  trackById(index: number, data: any): string {
    return data.Id;
  }
  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }
  LoadProfileTab() {
    this.DisableSubgroupDropdown = false;
    this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    this.demographicService.getE2EDemographics(this.endToEndId, this.endToEndType, "Profile", this.RoleTypeCode, this.CreatePage).subscribe((response: any) => {
      this.E2eDemoGraphics = response;
      this.profileDetails = response.ProfileDetails;
      this.DeliveryPortfolioDetails = response.DeliveryPortfolioDetails;
      this.tempRDE = this.DeliveryPortfolioDetails.RbeId;
      if (this.endToEndType != 'C') {
        this.demographicService.getOpportunityInitiativeDetails(this.endToEndType).subscribe(result => {
          this.profileDetails.OpportunityDetails = result != null ? result.OpportunityDetails : null;
          this.profileDetails.InitiativeDetails = result != null ? result.InitiativeDetails : null;
        })
      }
      this.ExistingTransitionContractID = cloneDeep(this.profileDetails.TransitionContractId);
      this.ExistingTransitionContractStatus = cloneDeep(this.profileDetails.ContractTransitionStatus);
      this.DisableSave = false;
      this.ExistingE2eDemoGraphics = cloneDeep(response);
      if (!this.CreatePage) {
        this.sessionStorage.restrictedInstanceIndicator = this.profileDetails.RestrictedInstanceIndicator;
        this.RoleAccess = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
      }
      this.OpportunityCode = this.profileDetails.OpportunityCode;
      this.OpportunityName = this.profileDetails.Opportunity;
      this.InitiativeName = this.profileDetails.Initiative;
      this.Contract = this.profileDetails.Contract;
      this.profileDetails.LifeCyclePhaseId = (this.profileDetails.LifeCyclePhaseId == 0) ? null : this.profileDetails.LifeCyclePhaseId;
      this.profileDetails.DeployRegionName = (this.profileDetails.DeployRegionName == 0) ? null : this.profileDetails.DeployRegionName;
      this.selectedDeployRegionName = this.profileDetails.DeployRegionName != null ? (this.profileDetails.DeployRegionName == 1 ? "ANZ" : this.profileDetails.DeployRegionName == 2 ? "EU" : this.profileDetails.DeployRegionName == 3 ? "US" : "") : "";
      this.profileDetails.BcmPriorityId = (this.profileDetails.BcmPriorityId == 0) ? null : this.profileDetails.BcmPriorityId;
      this.TestRecordIndicator = [{ Id: 'Y', Name: 'Yes' }, { Id: 'N', Name: 'No' }];
      this.SIFlag = [{ Id: 'Y', Name: 'Yes' }, { Id: 'N', Name: 'No' }];

      $("[name='profileform']").find('input[type!=hidden]:first').focus();
      this.StartDate = this.datepipe.transform(this.profileDetails.StartDate, 'dd MMM yyyy');

      this.EndDate = this.datepipe.transform(this.profileDetails.EndDate, 'dd MMM yyyy');
      $('#StartDate').val(this.StartDate);
      $('#EndDate').val(this.EndDate);
      if (this.path.indexOf('Opportunity') > -1 || this.path.indexOf('InternalInitiative') > -1) {
        if (this.path.indexOf('Opportunity') > -1) {
          this.SetActiveTabSelection.emit({ page: "Opportunity" });
        }
        else {
          this.SetActiveTabSelection.emit({ page: "InternalInitiative" });
        }
      }
      else {
        this.SetActiveTabSelection.emit({ page: "Delivery" });
      }
      if (this.CreatePage == false) {
        if (this.DeliveryPortfolioDetails.ManagementMarketId != null || this.DeliveryPortfolioDetails.ManagementMarketId != undefined) {
          var marketUnitList = this.DeliveryPortfolioDetails.ManagementMarketUnits;
          var marketUnit = marketUnitList.filter((x: any) => x.ParentId == this.DeliveryPortfolioDetails.ManagementMarketId);
          this.DeliveryPortfolioDetails.MMU = marketUnit;
        }
        else {
          this.DeliveryPortfolioDetails.MMU = cloneDeep(this.DeliveryPortfolioDetails.ManagementMarketUnits);
        }
        this.IsValidated = false;
        if (this.DeliveryPortfolioDetails.SelectedSubgroupCategory != null && this.DeliveryPortfolioDetails.SelectedDeliverySubgroup != null) {
          this.DisableSubgroupDropdown = true;
        }
        if (this.Role == "Admin - Avanade" && this.DeliveryPortfolioDetails.CompanyId == 1 && this.DeliveryPortfolioDetails.AvanadeChannelId == 0) {
          this.IsSIFlagDisable = true;
        }
        if (this.Role == "Admin - Accenture") {
          if (!(this.DeliveryPortfolioDetails.AvanadeChannelId == 1)) {
            this.IsCompanyDisable = true;
          }
          else {
            this.IsCompanyDisable = false;
          }
        }
        else if (this.Role == "Admin - Avanade") {
          if (this.DeliveryPortfolioDetails.AvanadeChannelId != 1) {
            this.IsCompanyDisable = true;
          }
          else {
            this.IsCompanyDisable = false;
          }

        }
        this.ValidationMarketUnit = null;
        if (this.endToEndType == "C" && this.DeliveryPortfolioDetails.RbeId != null &&
          (this.DeliveryPortfolioDetails.RbeId == 4 || (this.DeliveryPortfolioDetails.CompanyId == 2
            || this.DeliveryPortfolioDetails.CompanyId == 1))) {

          if (this.DeliveryPortfolioDetails.IsAccentureCGDominant || this.DeliveryPortfolioDetails.IsAvanadeGUDominant) {
            this.ValidationMarketUnit = true;
          }
          this.avanadeGeoUnitChange(this.DeliveryPortfolioDetails.AvanadeGeographicUnitId);
        }
        else if (this.endToEndType != "C" && this.DeliveryPortfolioDetails.CompanyId == 2) {
          if (this.DeliveryPortfolioDetails.IsAccentureCGDominant || this.DeliveryPortfolioDetails.IsAvanadeGUDominant) {
            this.ValidationMarketUnit = true;
          }
          this.avanadeGeoUnitChange(this.DeliveryPortfolioDetails.AvanadeGeographicUnitId);
        }
        else if (this.DeliveryPortfolioDetails.CompanyId == 2 || this.DeliveryPortfolioDetails.CompanyId == 1) {
          this.avanadeGeoUnitChange(this.DeliveryPortfolioDetails.AvanadeGeographicUnitId);
        }

        if (this.isAssociateContractPopupShow) {
          this.showManagePopup(this.profileDetails.AssociatedContractsList, 'Contract ID', 'profile', true);
        }

        if (this.endToEndType == "I" && this.DeliveryPortfolioDetails.ManagementMarketUnitId != null && this.DeliveryPortfolioDetails.ManagementClientGroupId == null) {
          this.MarketUnitChange(this.DeliveryPortfolioDetails.ManagementMarketUnitId);
        }
      }

      this.IsOppInitNotCreated = this.sessionStorage.createdE2EId == 0 ? true : false;
      this.InitiateProfileTabSetup.emit({ IsOppInitNotCreated: this.IsOppInitNotCreated, keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.Profile });
      this.IsDisableTestRecordIndicator = this.IsOppInitNotCreated ? false : true;
      window.scrollTo(0, $('.dynamic-content').offsetTop);
      setTimeout(function () {
        window.TooltipFunc();
      }, 0);
    });
    if (!this.IsAdmin && this.CreatePage)
      this.IsSIFlagDisable = true;
    this.sharedService.UsageLogging(enumModuleAction.Tile, enumTile.InitiateProfile, enumFeatureAccess.Profile);

  }


  OnRDEChange() {

    if (this.DeliveryPortfolioDetails.RbeId != 2 && this.endToEndType != 'I') {
      this.profileForm.form.get('bcmPriority').clearValidators();
      this.profileForm.form.get('bcmPriority').updateValueAndValidity();
    }
    //if (!this.CreatePage) {
    //  if (this.endToEndType != 'C' && this.DeliveryPortfolioDetails.RbeId == null) {

    //    this.sharedService.openAlertPopup("EndToEnd cannot be mapped to Avanade RDE. Please choose a different RDE.", window.scrollY, "Ok", false);
    //    this.profileForm.form.get('rde').updateValueAndValidity();

    //    return false;
    //  }
    //}   
  }

  dateChanged() {
    this.profileForm.form.markAsDirty();
  }

  validateDateFormat(dateValue) {
    if (dateValue != undefined && dateValue != "" && dateValue != null) {
      if (dateValue.match(/^(([0-9])|([0-2][0-9])|([3][0-1]))\ (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\ \d{4}$/))
        return true;
      else
        return false;
    }
    else {
      return false;
    }
  }

  async SaveProfile(formvalid, form) {

    if (!this.validateDateFormat(this.StartDate.toString()) || !this.validateDateFormat(this.EndDate.toString())) {
      formvalid = false;
    }
    this.endDateValidation.IsValidEndDate = true;
    if (formvalid && this.ValidateStartDateEndaDate() && this.validateEndDate() && $('#profile').find('.has-error').length < 1) {
      this.E2eDemoGraphics.EnterpriseId = this.sessionStorage.enterpriseId;
      if (this.endToEndType == 'O') {
        this.profileDetails.EndToEndName = this.profileDetails.Opportunity;
      }
      else if (this.endToEndType == 'I') {
        this.profileDetails.EndToEndName = this.profileDetails.Initiative;
      }
      this.profileDetails.StartDate = $(('#StartDate')).val();
      this.profileDetails.EndDate = $(('#EndDate')).val();
      if (this.Validate()) {
        if (this.endToEndType == 'O' && this.ExistingTransitionContractID != this.profileDetails.TransitionContractId) {
          this.profileDetails.IsContractIdUpdated = true;
        }
        if ((this.OpportunityName != this.profileDetails.Opportunity || this.InitiativeName != this.profileDetails.Initiative) && this.CreatePage == true) {
          var opportunityData = Enumerable.from(this.profileDetails.OpportunityDetails).where((x: any) => x.ParentName == this.profileDetails.Opportunity).firstOrDefault();
          var initiativeData = Enumerable.from(this.profileDetails.InitiativeDetails).where((x: any) => x.ParentName == this.profileDetails.Initiative).firstOrDefault();
          if (opportunityData || initiativeData) {
            var message1 = 'Opportunity with this name already exist. Click Ok to continue';
            var message2 = 'Initiative with this name already exist. Click Ok to continue';
            var message = (this.endToEndType == 'O') ? message1 : message2;
            var confirmationModel = this.sharedService.openConfirmationPopup(message, "Ok", "Cancel", window.scrollY)//,function(){this.test()}, function(){this.ConfirmedNo()}); //ConfirmedYes(), ConfirmedNo());
            confirmationModel.content.confirmationResult.subscribe(response => {
              if (response.result) {
                var result = this.sharedService.openConfirmationPopup("Kindly review your selection made against the field 'Test Record' as it cannot be reversed. Test Record:Yes, denotes a dummy record used for sanity testing and/or operational support. Test Record:No, denotes a valid account created per request from end user.\nClick on OK to confirm your selection and Cancel to return to your changes.", "OK", "Cancel", window.scrollY);
                result.content.confirmationResult.subscribe(response => {
                  if (!response.result) {
                    return false;
                  } else {
                    //Making Master data as null to avoid performance issue on save due to huge data passed in the payload
                    this.profileDetails.OpportunityDetails = null;
                    this.profileDetails.InitiativeDetails = null;
                    this.E2eDemoGraphics.RoleTypeCode = this.RoleAccess;
                    this.demographicService.postE2EDemographics(this.E2eDemoGraphics).subscribe((response: any) => {
                      this.sessionStorage.isValidEndDate = null;
                      this.ProfileTabHighlight = false;
                      var tempLifeCyclePhase = this.E2eDemoGraphics && this.E2eDemoGraphics.ProfileDetails ? this.E2eDemoGraphics.ProfileDetails.LifeCyclePhaseId : 0;

                      if (this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1) {
                        this.sharedService.openAlertPopup("Saved Successfully. Please Fill up the mandatory fields in Scope tab, Delivery Portfolio tab and Key Contacts Tab", window.scrollY);
                        //this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, response, this.demographicService);
                        this.IsDisableTestRecordIndicator = true;
                      }
                      else {
                        this.sharedService.openAlertPopup("Saved Successfully", window.scrollY);
                        //this.sharedService.ProcessDemographicAttribute((tempLifeCyclePhase == 9 ? null : enumEntityType.EndToEnd), this.sessionStorage.endToEndId, this.demographicService);

                      }
                      this.IsOppInitNotCreated = false;
                      if (response != "Error while saving demographics" && response != "Saved Successfully") {
                        this.endToEndId = response;
                        this.sessionStorage.createdE2EId = response;

                        this.E2eDemoGraphics.E2EId = response;

                      }
                      this.DisplayName = this.profileDetails.DisplayName;
                      if (form != undefined) form.resetForm();
                      if (form.enddateform) {
                        form.enddateform.touched = false;
                      }
                      if (form.startdateform) {
                        form.startdateform.touched = false;
                      }
                      this.LoadProfileTab();
                      this.initiateProfileComponent.LoadMandatoryDataAttrSet();
                      if (tempLifeCyclePhase == 9) {
                        this.CloseCMSTMSWorkstream();
                        this.sessionStorage.endToEndId = 0;
                        this.sessionStorage.endToEndUId = '';
                        this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
                        this.sessionStorage.clientWMSId = 0;
                        this.sessionStorage.clientWMSUId = '';
                        this.sessionStorage.clientId = 0;
                        this.sessionStorage.clientUId = '';
                        this.sessionStorage.teamId = 0;
                        this.sessionStorage.teamUId = "";
                        this.sessionStorage.clientName = "";
                        this.sessionStorage.cmsName = "";
                        this.sessionStorage.endToEndName = "";
                        this.sessionStorage.tmsName = "";
                        this.sessionStorage.deployRegion = '';
                        this.sessionStorage.workstreamId = 0;
                        this.sessionStorage.workstreamUId = "";
                        this.sessionStorage.workstreamName = "";
                        this.broadCastService.broadcastScopeSelector({
                          isScopeSelectorVisible: true, isScopeSelectorDropDownVisible: true,
                          isSetAsDefaultVisible: true, isDisableTeamScopeSelector: true
                        });
                        this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: true });

                      }
                      else if (!(this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1)) {

                        if (this.endToEndType == "I") {
                          this.DisplayName = this.profileDetails.DisplayName = this.profileDetails.EndToEndName;
                        }
                        this.sessionStorage.endToEndName = this.DisplayName ? this.DisplayName : this.profileDetails.EndToEndName;
                        if (this.sessionStorage.clientName != this.profileDetails.ClientName) {
                          this.sessionStorage.clientName = this.profileDetails.ClientName;
                          this.sessionStorage.clientId = this.profileDetails.ClientId;

                        }
                        this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
                      }
                    });
                  }
                });
              }

            });
          }
          else {
            var result = this.sharedService.openConfirmationPopup("Kindly review your selection made against the field 'Test Record' as it cannot be reversed. Test Record:Yes, denotes a dummy record used for sanity testing and/or operational support. Test Record:No, denotes a valid account created per request from end user.\nClick on OK to confirm your selection and Cancel to return to your changes.", "OK", "Cancel", window.scrollY);
            result.content.confirmationResult.subscribe(response => {
              if (!response.result) {
                return false;
              } else {
                //Making Master data as null to avoid performance issue on save due to huge data passed in the payload
                this.profileDetails.OpportunityDetails = null;
                this.profileDetails.InitiativeDetails = null;
                this.E2eDemoGraphics.RoleTypeCode = this.RoleAccess;
                this.demographicService.postE2EDemographics(this.E2eDemoGraphics).subscribe((response: any) => {
                  this.sessionStorage.isValidEndDate = null;
                  this.ProfileTabHighlight = false;
                  var tempLifeCyclePhase = this.E2eDemoGraphics && this.E2eDemoGraphics.ProfileDetails ? this.E2eDemoGraphics.ProfileDetails.LifeCyclePhaseId : 0;

                  if (this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1) {
                    this.sharedService.openAlertPopup("Saved Successfully. Please Fill up the mandatory fields in Scope tab, Delivery Portfolio tab and Key Contacts Tab", window.scrollY);
                    //this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, response, this.demographicService);
                    this.IsDisableTestRecordIndicator = true;
                  }
                  else {
                    this.sharedService.openAlertPopup("Saved Successfully", window.scrollY);
                    //this.sharedService.ProcessDemographicAttribute((tempLifeCyclePhase == 9 ? null : enumEntityType.EndToEnd), this.sessionStorage.endToEndId, this.demographicService);

                  }
                  this.IsOppInitNotCreated = false;
                  if (response != "Error while saving demographics" && response != "Saved Successfully") {
                    this.endToEndId = response;
                    this.sessionStorage.createdE2EId = response;

                    this.E2eDemoGraphics.E2EId = response;

                  }
                  this.DisplayName = this.profileDetails.DisplayName;
                  if (form != undefined) form.resetForm();
                  if (form.enddateform) {
                    form.enddateform.touched = false;
                  }
                  if (form.startdateform) {
                    form.startdateform.touched = false;
                  }
                  this.LoadProfileTab();
                  this.initiateProfileComponent.LoadMandatoryDataAttrSet();
                  if (tempLifeCyclePhase == 9) {
                    this.CloseCMSTMSWorkstream();
                    this.sessionStorage.endToEndId = 0;
                    this.sessionStorage.endToEndUId = '';
                    this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
                    this.sessionStorage.clientWMSId = 0;
                    this.sessionStorage.clientWMSUId = '';
                    this.sessionStorage.clientId = 0;
                    this.sessionStorage.clientUId = '';
                    this.sessionStorage.teamId = 0;
                    this.sessionStorage.teamUId = "";
                    this.sessionStorage.clientName = "";
                    this.sessionStorage.cmsName = "";
                    this.sessionStorage.endToEndName = "";
                    this.sessionStorage.tmsName = "";
                    this.sessionStorage.deployRegion = '';
                    this.sessionStorage.workstreamId = 0;
                    this.sessionStorage.workstreamUId = "";
                    this.sessionStorage.workstreamName = "";
                    this.broadCastService.broadcastScopeSelector({
                      isScopeSelectorVisible: true, isScopeSelectorDropDownVisible: true,
                      isSetAsDefaultVisible: true, isDisableTeamScopeSelector: true
                    });
                    this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: true });

                  }
                  else if (!(this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1)) {

                    if (this.endToEndType == "I") {
                      this.DisplayName = this.profileDetails.DisplayName = this.profileDetails.EndToEndName;
                    }
                    this.sessionStorage.endToEndName = this.DisplayName ? this.DisplayName : this.profileDetails.EndToEndName;
                    if (this.sessionStorage.clientName != this.profileDetails.ClientName) {
                      this.sessionStorage.clientName = this.profileDetails.ClientName;
                      this.sessionStorage.clientId = this.profileDetails.ClientId;

                    }
                    this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
                  }
                });
              }
            });
          }
        }
        else {
          //Making Master data as null to avoid performance issue on save due to huge data passed in the payload
          this.profileDetails.OpportunityDetails = null;
          this.profileDetails.InitiativeDetails = null;
          this.E2eDemoGraphics.RoleTypeCode = this.RoleAccess;
          this.demographicService.postE2EDemographics(this.E2eDemoGraphics).subscribe((response: any) => {
            if (response) {
              this.sessionStorage.isValidEndDate = null;
              this.ProfileTabHighlight = false;
              var tempLifeCyclePhase = this.E2eDemoGraphics && this.E2eDemoGraphics.ProfileDetails ? this.E2eDemoGraphics.ProfileDetails.LifeCyclePhaseId : 0;

              if (this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1) {
                this.sharedService.openAlertPopup("Saved Successfully. Please Fill up the mandatory fields in Scope tab, Delivery Portfolio tab and Key Contacts Tab", window.scrollY);
                //this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, response, this.demographicService);
                this.IsDisableTestRecordIndicator = true;
              }
              else {
                this.sharedService.openAlertPopup("Saved Successfully", window.scrollY);
                //this.sharedService.ProcessDemographicAttribute((tempLifeCyclePhase == 9 ? null : enumEntityType.EndToEnd), this.sessionStorage.endToEndId, this.demographicService);

              }
              this.IsOppInitNotCreated = false;
              if (response != "Error while saving demographics" && response != "Saved Successfully") {
                this.endToEndId = response;
                this.sessionStorage.createdE2EId = response;

                this.E2eDemoGraphics.E2EId = response;

              }
              this.DisplayName = this.profileDetails.DisplayName;
              if (form != undefined) form.resetForm();
              if (form.enddateform) {
                form.enddateform.touched = false;
              }
              if (form.startdateform) {
                form.startdateform.touched = false;
              }
              this.LoadProfileTab();
              this.initiateProfileComponent.LoadMandatoryDataAttrSet();
              if (tempLifeCyclePhase == 9) {
                this.CloseCMSTMSWorkstream();
                this.sessionStorage.endToEndId = 0;
                this.sessionStorage.endToEndUId = '';
                this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
                this.sessionStorage.clientWMSId = 0;
                this.sessionStorage.clientWMSUId = '';
                this.sessionStorage.clientId = 0;
                this.sessionStorage.clientUId = '';
                this.sessionStorage.teamId = 0;
                this.sessionStorage.teamUId = "";
                this.sessionStorage.clientName = "";
                this.sessionStorage.cmsName = "";
                this.sessionStorage.endToEndName = "";
                this.sessionStorage.tmsName = "";
                this.sessionStorage.deployRegion = '';
                this.sessionStorage.workstreamId = 0;
                this.sessionStorage.workstreamUId = "";
                this.sessionStorage.workstreamName = "";
                this.broadCastService.broadcastScopeSelector({
                  isScopeSelectorVisible: true, isScopeSelectorDropDownVisible: true,
                  isSetAsDefaultVisible: true, isDisableTeamScopeSelector: true
                });
                this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: true });

              }
              else if (!(this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1)) {

                if (this.endToEndType == "I") {
                  this.DisplayName = this.profileDetails.DisplayName = this.profileDetails.EndToEndName;
                }
                this.sessionStorage.endToEndName = this.DisplayName ? this.DisplayName : this.profileDetails.EndToEndName;
                if (this.sessionStorage.clientName != this.profileDetails.ClientName) {
                  this.sessionStorage.clientName = this.profileDetails.ClientName;
                  this.sessionStorage.clientId = this.profileDetails.ClientId;

                }
                this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });

              }
            }
          });
        }
      }
    }
    else {
      this.sharedService.openAlertPopup('Please review the highlighted fields and update accordingly.', window.scrollY, "Ok", false);
      await this.initiateProfileSharedService.focusErrorField('profileform');
    }
    this.TabChanges = false;
    this.broadCastService.broadcastRefreshScopeSelectorData({ IsLoad: false });
  }
  profileCompletedStatus(status) {
    this.IsProfileCompleted = status;
  }

  ValidateStartDateEndaDate() {    
    if (!dayjs(this.StartDate, 'DD MMM YYYY', true).isValid() || !dayjs(this.EndDate, 'DD MMM YYYY', true).isValid()) {
      return false;
    }
    if (!(dayjs(this.EndDate, 'DD MMM YYYY').format('DD MMM YYYY') === this.EndDate)) {

      return this.endDateValue = false;
    }
    if (!(dayjs(this.StartDate, 'DD MMM YYYY').format('DD MMM YYYY') === this.StartDate)) {
      return this.startDateValue = false;
    }

    if (this.endToEndType == "C") {
      if ($('#StartDate').val() != '' &&
        $('#EndDate').val() != '') {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  validateEndDate() {
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    this.EndDate = $(('#EndDate')).val();
    if (new Date(this.EndDate) < currentDate) {
      this.futureDateValidation.IsFutureEndDate = false;
    }
    else {
      this.futureDateValidation.IsFutureEndDate = true;
      return true;
    }
  }


  Validate() {
    if (this.OpportunityCode != this.profileDetails.OpportunityCode) {
      var pattern = /^(?=.*[A-z]).+$/;
      if (!pattern.test(this.profileDetails.OpportunityCode) && this.profileDetails.OpportunityCode.startsWith("0")) {
        this.profileDetails.OpportunityCode = parseInt(this.profileDetails.OpportunityCode).toString();
      }
      var opportunityData = Enumerable.from(this.profileDetails.OpportunityDetails).where((x: any) => x.Name == this.profileDetails.OpportunityCode).firstOrDefault();
      if (opportunityData) {
        this.sharedService.openAlertPopup('Opportunity Id exists.', window.scrollY, "Ok", false);
        return false;
      }
    }
    this.StartDate = $('#StartDate').val();
    this.EndDate = $('#EndDate').val();
    if (this.profileDetails.DeployRegionName == 2 && this.DeliveryPortfolioDetails.ManagementMarketUnitId == 7) {
      this.sharedService.openAlertPopup('Market Unit cannot be ‘Greater China’ when Deploy Region is ‘EU’. Please update the Market Unit to proceed.', window.scrollY, "Ok", false);
      return false;
    }
    if (new Date(this.StartDate) > new Date(this.EndDate)) {
      this.sharedService.openAlertPopup('Start date can not be greater than the End date.', window.scrollY, "Ok", false);
      return false;
    }
    if (this.endToEndType == 'O' && this.profileDetails.TransitionContractId != undefined && (this.profileDetails.TransitionContractId.toString().length > 0 && this.profileDetails.TransitionContractId.toString().length < 10)) {
      this.sharedService.openAlertPopup('Please enter 10 digit contract id', window.scrollY, "Ok", false);
      return false;
    }
    if (this.CreatePage == false) {
      if (this.DeliveryPortfolioDetails.IsAccentureCGDominant || this.DeliveryPortfolioDetails.IsAvanadeGUDominant) {
        if ((this.DeliveryPortfolioDetails.AvanadeGSAId == null || this.DeliveryPortfolioDetails.AvanadeGSAId == 'undefined') &&
          (this.DeliveryPortfolioDetails.RbeId == 4 || this.DeliveryPortfolioDetails.CompanyId == 2)) {
          if (this.DeliveryPortfolioDetails.RbeId == 4 && this.DeliveryPortfolioDetails.AvanadeChannelId != 0) {
            this.sharedService.openAlertPopup("Please Fill Avanade GSA.", window.scrollY, "Ok", false);
            return false;
          }
        }
        if (this.endToEndType != "C" && (this.DeliveryPortfolioDetails.AvanadeGSAId == null ||
          this.DeliveryPortfolioDetails.AvanadeGSAId == 'undefined') && this.DeliveryPortfolioDetails.CompanyId == 2) {
          if (this.DeliveryPortfolioDetails.AvanadeChannelId != 0) {
            this.sharedService.openAlertPopup("Please Fill Avanade GSA.", window.scrollY, "Ok", false);
            return false;
          }
        }
      }
    }
    else {
      var showAlert = false;
      if (this.endToEndType == "I" && this.profileDetails.InitiativeId == 0) {
        showAlert = true;
      }
      else if (this.endToEndType == "O" && this.profileDetails.OpportunityId == 0) {
        showAlert = true;
      }
      // if (showAlert) {
      // }
    }
    return true;

  }

  showIndustrySegment() {

    var industrylist = this.DeliveryPortfolioDetails.IndustrySegments;
    var selectedindustrysegment = this.DeliveryPortfolioDetails.IndustryInputs;
    var industrygriddata = Enumerable.from(industrylist).join(
      selectedindustrysegment, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();

    var involvedFactoriesList = this.DeliveryPortfolioDetails.InvolvedFactories;
    var selectedInvolvedFactory = this.DeliveryPortfolioDetails.InvolvedFactoryInputs;
    var gridData = Enumerable.from(involvedFactoriesList).join(
      selectedInvolvedFactory, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();

    //const initialState = { title: "Industry Segments", label: "Industry Segment", gridData: industrygriddata, masterData: this.DeliveryPortfolioDetails.IndustrySegments };
    //var modelRef = this.modalService.show(ManageDemographicsComponent, {
    //  initialState,
    //  backdrop: "static",
    //  keyboard: false
    //});
    //$(".modal-dialog").addClass('custom-modal');

    //modelRef.content.manageDemographicOutput.subscribe(result => {
    //  this.DeliveryPortfolioDetails.IndustrySegement = result.gridData.map(x => x.Name).join();
    //  this.DeliveryPortfolioDetails.IndustryInputs = result.gridData.map(({ Id }) => Id);
    //});

  }

  showManagePopup(attributeVal, name, entity, showStatus) {


    var inputGridValues = cloneDeep(attributeVal);
    const initialState = {
      attributeName: name,
      gridData: inputGridValues,
      entity: entity,
      showStatus: showStatus,
      profileDetails: this.profileDetails,
      E2eDemoGraphics: this.E2eDemoGraphics,
      DeliveryPortfolioDetails: this.DeliveryPortfolioDetails


    };
    setTimeout(function () {
      window.TooltipFunc();
    }, 500);

    var modalManage = this.modalService.show(ManageContractsComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
    $(".modal-dialog").addClass('custom-contract-modal');
    modalManage.content.contracts.subscribe(result => {
      this.LoadProfileTab();

    });

  }

  CloseCMSTMSWorkstream() {
    this.demographicService.closeCMSTMSWorkstream(this.sessionStorage.endToEndUId).subscribe((response) => {
    });
  }


  showContractInfo() {
    const initialState = { financialActiveContracts: this.profileDetails.FinancialActiveContracts, financialInactiveContracts: this.profileDetails.FinancialInactiveContracts, DeliveryPortfolioDetails: this.DeliveryPortfolioDetails };
    this.modalService.show(MultiContractDetailComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
  }


  cancel(form) {
    var formname = 'profileform'
    if (form != undefined) {
      form.resetForm();
      formname = form.name;
    }
    this.LoadProfileTab();
  }

  ChangeLifeCycle() {
    if (this.profileDetails.LifeCyclePhaseId == 9) {
      if (this.endToEndType == 'C') {
        var message = 'You are trying to close the contract. Contracts will not be visible in the system once updated to "Closed". Please click on Ok to proceed.';
      }
      else if (this.endToEndType == 'O') {
        var message = 'You are trying to close the opportunity. Opportunities will not be visible in the system once updated to "Closed". Please click on Ok to proceed.';
      }
      else if (this.endToEndType == 'I') {
        var message = 'You are trying to close the initiative. Initiatives will not be visible in the system once updated to "Closed". Please click on Ok to proceed.';
      }

      var confirmationModel = this.sharedService.openConfirmationPopup(message, "Ok", "Cancel", window.scrollY)//,function(){this.test()}, function(){this.ConfirmedNo()}); //ConfirmedYes(), ConfirmedNo());
      confirmationModel.content.confirmationResult.subscribe(response => {
        if (response.result) {
          this.profileDetails.LifeCycleId = this.profileDetails.LifeCyclePhaseId;
        } else {
          this.profileDetails.LifeCyclePhaseId = this.profileDetails.LifeCycleId;
        }
      });

    }
    else {
      this.profileDetails.LifeCycleId = this.profileDetails.LifeCyclePhaseId;
    }
  }

  ChangeDeployRegion() {
    this.profileDetails.DeployRegionName = this.profileDetails.DeployRegionName;
  }

  TestRecordChange() {
    this.profileDetails.TestRecordIndicator = this.profileDetails.TestRecordIndicator;
  }

  SIFlagChange() {
    this.profileDetails.SIFlag = this.profileDetails.SIFlag;
  }

  CompanyChange(Id) {
    this.initiateProfileSharedService.CompanyChange(Id, this);
  }

  ManagementMarkeChange(Id) {
    this.initiateProfileSharedService.ManagementMarkeChange(Id, this);
  }

  MarketUnitChange(Id) {
    this.initiateProfileSharedService.MarketUnitChange(Id, this);
  }

  ClientGroupChange() {
    this.initiateProfileSharedService.ClientGroupChange(this);
  }

  showInvolvedFactories() {
    this.initiateProfileSharedService.showInvolvedFactories(this);
  }


  SubgroupCategoryChange() {
    this.initiateProfileSharedService.SubgroupCategoryChange(this);
  }

  SubGroupChange() {
    this.initiateProfileSharedService.SubGroupChange(this);
  }

  avanadeChannelChange() {

    this.initiateProfileSharedService.avanadeChannelChange(this);

  }

  avanadeGeoUnitChange(Id, eventflag?) {
    this.initiateProfileSharedService.avanadeGeoUnitChange(Id, this, eventflag);
  }

  onChangeEndDate() {
    this.endDateValidation.IsValidEndDate = true;
    this.futureDateValidation.IsFutureEndDate = true;
  }
  OnPaste(event, field) {
    var totalValue = '';
    var pastedValue = '';
    if ((window as any).clipboardData && (window as any).clipboardData.getData) { // IE
      pastedValue = (window as any).clipboardData.getData('Text');
    }
    else if (event.clipboardData && event.clipboardData.getData) { // other browsers
      pastedValue = event.clipboardData.getData('text/plain');
    }
    if (this.ValidationAlphaNumeric(pastedValue)) {
      totalValue = pastedValue;
      var totalLength = totalValue.length;
      pastedValue = '';
      if (field == 'OpportunityId')
        this.profileDetails.OpportunityCode = totalValue.substring(0, 11);
    }
    event.stopPropagation();
    event.preventDefault();

  }
  ValidationAlphaNumeric(string) {
    var pattern = /^[ A-Za-z0-9/]*$/;
    return pattern.test(string);
  }
  KeyPressAlphanumeric(event) {
    var iKeyCode = event.keyCode;
    if ((iKeyCode >= 48 && iKeyCode <= 57) || (iKeyCode >= 65 && iKeyCode <= 90) || (iKeyCode >= 97 && iKeyCode <= 122)) {
      return true;
    }
    event.stopPropagation();
    event.preventDefault();
  }

  OnPasteNumericTransition(event) {
    var totalValue = '';
    var pastedValue = '';
    if (((window as any) as any).clipboardData && ((window as any) as any).clipboardData.getData) { // IE
      pastedValue = (window as any).clipboardData.getData('Text');
    }
    else if (event.clipboardData && event.clipboardData.getData) {
      pastedValue = event.clipboardData.getData('text/plain');
    }
    if (this.ValidationNumeric(pastedValue)) {
      totalValue = pastedValue;
      pastedValue = '';
      this.profileDetails.TransitionContractId = parseFloat(totalValue.substring(0, 10));
      this.OnContractIDChange(this.profileDetails.TransitionContractId);
    }
    event.stopPropagation();
    event.preventDefault();
  }

  ValidationNumeric(string) {
    var pattern = /^\d+$/;
    return pattern.test(string);
  }
  OnContractIDChange(contractID) {
    if (contractID != undefined && contractID.toString().length == 10) {
      if (this.ExistingTransitionContractID == this.profileDetails.TransitionContractId) {
        this.profileDetails.IsContractIdValidated = true;
        this.profileDetails.ContractTransitionStatus = this.ExistingTransitionContractStatus;
        return false;
      }
      this.demographicService.getContractTransitionStatus(contractID).subscribe((response: any) => {
        this.profileDetails.IsContractIdValidated = true;
        this.profileDetails.ContractTransitionStatus = response;
        this.DisableSave = response.includes('Rejected') ? true : false;
      });
    }
    else {
      this.profileDetails.IsContractIdValidated = false;
      this.profileDetails.ContractTransitionStatus = "";
      this.DisableSave = false;
    }
  }

  KeyPressNumericTransition(event) {
    var iKeyCode = event.keyCode;
    if ((iKeyCode >= 48 && iKeyCode <= 57)) {
      return true;
    }
    event.stopPropagation();
    event.preventDefault();
  }


  showCustomer() {
    const initialState = { profileDetails: this.profileDetails, endToEndType: this.endToEndType };
    this.modalRefCustomerPopup = this.modalService.show(ManageCustomerComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });

    this.modalRefCustomerPopup.content.altnernateContactDetails.subscribe(result => {
      if (result.saveData) {
        this.profileForm.form.markAsDirty();
      }
    });

  }

  showBCMPriority() {
    const initialState = { scrollYPosition: window.scrollY }
    this.modalService.show(BCMPriorityDescriptionComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
  }
  trackByName(index: number, item: any): string {
    return item.Name;
  }
}

import { Injectable, EventEmitter } from '@angular/core';
import { SessionStorage } from '../../core/context/sessionstorage';
import * as Enumerable from 'linq';
import cloneDeep from 'lodash/cloneDeep';
//import { ManageDemographicsComponent } from './model.popup.component/manage.demographics.component';
import { enumRoleType } from '../../core/context/enum';
import { SharedService } from '../../core/services/shared.service';

var $ = (<any>window).$;
var $ = (<any>window).$;
@Injectable()
export class InitiateProfileSharedService {
  public errorTabHighlight: EventEmitter<any> = new EventEmitter();
  constructor(private sessionStorage: SessionStorage,private sharedService: SharedService
    ) {

  }



  SetupOppInitiCreator(componentRef) {

    componentRef.isOpportunityCreator = this.sessionStorage.customRoleTypeCode && this.sessionStorage.customRoleTypeCode.filter(x => x == enumRoleType.OpportunityCreator)[0] ? true : false;
    componentRef.isInitiativeCreator = this.sessionStorage.customRoleTypeCode && componentRef.sessionStorage.customRoleTypeCode.filter(x => x == enumRoleType.InitiativeCreator)[0] ? true : false;

    if (componentRef.isOpportunityCreator || componentRef.isInitiativeCreator) {
      componentRef.IsOpportunityInitiativeCreator = true;
      componentRef.RoleAccess = 'E';
    }

  }

  SetupProfileAndAdminPage(componentRef) {
    if (componentRef.path.indexOf('opportunity') > -1) {
      componentRef.DeliveryProfile = 'Opportunity';
      componentRef.CreatePage = true;
      componentRef.endToEndId = 0;
      componentRef.endToEndType = 'O';
      componentRef.RoleTypeCode = true;
      if (this.sessionStorage.AdminAccessType == null || this.sessionStorage.AdminAccessType == undefined) {
        componentRef.RoleAccess = (this.sessionStorage.roleTypeCode == null || this.sessionStorage.roleTypeCode == undefined) ? 'V' : (this.sessionStorage.roleTypeCode.indexOf('A') == 0) ? 'E' : 'V';
      }
      else {
        componentRef.RoleAccess = this.sessionStorage.AdminAccessType.indexOf('A') == 0 ? 'E' : 'V';
      }

      this.SetupOppInitiCreator(componentRef);
      componentRef.EnableDeliveryProfileGuide = false;
      componentRef.IsOppInitNotCreated = true;
      componentRef.SubmissionType = 1;
      componentRef.headerComponent.ScopeSelecorVisible({
        isScopeSelectorVisible: false, isScopeSelectorDropDownVisible: false,
        isSetAsDefaultVisible: false, isDisableTeamScopeSelector: true
      });


    }
    else if (componentRef.path.indexOf('internalinitiative') > -1) {
      componentRef.DeliveryProfile = 'Internal Initiative';
      componentRef.CreatePage = true;
      componentRef.endToEndId = 0;
      componentRef.endToEndType = 'I';
      componentRef.RoleTypeCode = true;
      if (this.sessionStorage.AdminAccessType == null || this.sessionStorage.AdminAccessType == undefined) {
        componentRef.RoleAccess = (this.sessionStorage.roleTypeCode == null || this.sessionStorage.roleTypeCode == undefined) ? 'V' : (this.sessionStorage.roleTypeCode.indexOf('A') == 0) ? 'E' : 'V';
      }
      else {
        componentRef.RoleAccess = this.sessionStorage.AdminAccessType.indexOf('A') == 0 ? 'E' : 'V';
      }
      this.SetupOppInitiCreator(componentRef);
      componentRef.EnableDeliveryProfileGuide = false;
      componentRef.IsOppInitNotCreated = true;
      componentRef.SubmissionType = 1;
      componentRef.headerComponent.ScopeSelecorVisible({
        isScopeSelectorVisible: false, isScopeSelectorDropDownVisible: false,
        isSetAsDefaultVisible: false, isDisableTeamScopeSelector: true
      });


    }
    else {
      componentRef.DeliveryProfile = 'Initiate Profile';
      componentRef.CreatePage = false;
      componentRef.IsOppInitNotCreated = false;
      componentRef.endToEndId = this.sessionStorage.endToEndId;
      componentRef.endToEndType = this.sessionStorage.endToEndType;
      componentRef.RoleTypeCode = (this.sessionStorage.roleTypeCode == null || this.sessionStorage.roleTypeCode == undefined) ? false : (this.sessionStorage.roleTypeCode.indexOf('A') == 0) ? true : false;
      componentRef.headerComponent.ScopeSelecorVisible({
        isScopeSelectorVisible: true, isScopeSelectorDropDownVisible: true,
        isSetAsDefaultVisible: false, isDisableTeamScopeSelector: false
      });

    }
  }
  CompanyChange(Id, componentRef) {
    if (Id == 2) {
      componentRef.DeliveryPortfolioDetails.AvanadeChannelId = 1;
      var count = Enumerable.from(componentRef.DeliveryPortfolioDetails.GlobalNetworkGridDataForOppInit).where((x: any) => x.DeliveryCenterId == 0 && x.LedByIndicator == true).select(s => s).count();
      if (count > 0) {
        componentRef.ValidationMarketUnit = true;
      }
    }
    else if (Id == 1) {
      componentRef.DeliveryPortfolioDetails.AvanadeChannelId = 0;
      componentRef.DeliveryPortfolioDetails.AvanadeGeographicUnitId = null;
      componentRef.DeliveryPortfolioDetails.AvanadeCountryId = null;

    }
  }

  ManagementMarkeChange(Id, componentRef) {
    if (Id == undefined) {
      componentRef.DeliveryPortfolioDetails.ManagementMarketId = null;
    }
    componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId = null;
    componentRef.DeliveryPortfolioDetails.ManagementMarketUnits.forEach((x: any) => {
      x.IsSelected = false;
    });

    componentRef.DeliveryPortfolioDetails.ManagementClientGroupId = null;
    componentRef.DeliveryPortfolioDetails.MCG.forEach((x: any) => {
      x.IsSelected = false;
    });

    if (componentRef.DeliveryPortfolioDetails.ManagementMarketId != null || componentRef.DeliveryPortfolioDetails.ManagementMarketId != undefined) {
      var marketUnitList = componentRef.DeliveryPortfolioDetails.ManagementMarketUnits;
      var marketUnit = marketUnitList.filter((x: any) => x.ParentId == componentRef.DeliveryPortfolioDetails.ManagementMarketId);
      componentRef.DeliveryPortfolioDetails.MMU = marketUnit;
    }
    else {
      componentRef.DeliveryPortfolioDetails.MMU = cloneDeep(componentRef.DeliveryPortfolioDetails.ManagementMarketUnits);

    }

    if (componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId != null || componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId != undefined) {
      var clientGroupList = componentRef.DeliveryPortfolioDetails.ManagementClientGroups;
      var clientGroup = Enumerable.from(clientGroupList).where((x: any) => x.ManagementMarketUnitId == componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId).groupBy(null, null, (x: any) => { x.Id, x.Name }, null).toArray();
      componentRef.DeliveryPortfolioDetails.MCG = clientGroup;
    }
    else {
      componentRef.DeliveryPortfolioDetails.MCG = cloneDeep(componentRef.DeliveryPortfolioDetails.ManagementClientGroups);
    }
  }

  MarketUnitChange(Id, componentRef) {
    componentRef.DeliveryPortfolioDetails.ManagementClientGroupId = null;
    componentRef.DeliveryPortfolioDetails.MCG.forEach((x: any) => {
      x.IsSelected = false;
    });
    if (componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId != null || componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId != undefined) {
      var clientGroupList = componentRef.DeliveryPortfolioDetails.ManagementClientGroups;
      var clientGroup = Enumerable.from(clientGroupList).where((x: any) => x.ManagementMarketUnitId == Id).groupBy(null, null, (x: any) => new Object({ Id: x.Id, Name: x.Name }), null).toArray();
      componentRef.DeliveryPortfolioDetails.MCG = clientGroup;
    }
    else {
      componentRef.DeliveryPortfolioDetails.MCG = cloneDeep(componentRef.DeliveryPortfolioDetails.ManagementClientGroups);
    }
  }

  ClientGroupChange = function (componentRef) {
    var csgp = componentRef.DeliveryPortfolioDetails.ManagementClientGroupId;
    if (csgp == null) {
      componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId = null;
      componentRef.DeliveryPortfolioDetails.ManagementMarketUnits.forEach((x: any) => {
        x.IsSelected = false;
      });

      componentRef.DeliveryPortfolioDetails.ManagementMarketId = null;
      componentRef.DeliveryPortfolioDetails.ManagementMarkets.forEach((x: any) => {
        x.IsSelected = false;
      });
    }
    else {

      let clientGroup: any = Enumerable.from(componentRef.DeliveryPortfolioDetails.ManagementClientGroups)
        .where((x: any) => x.Id == componentRef.DeliveryPortfolioDetails.ManagementClientGroupId).firstOrDefault();

      componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId = clientGroup != null ? clientGroup.ManagementMarketUnitId : 0

      var market: any = Enumerable.from(componentRef.DeliveryPortfolioDetails.ManagementClientGroups)
        .where((x: any) => x.ManagementMarketUnitId == componentRef.DeliveryPortfolioDetails.ManagementMarketUnitId).firstOrDefault();

      componentRef.DeliveryPortfolioDetails.ManagementMarketId = market != null ? market.ManagementMarketId : 0

      var marketUnit = componentRef.DeliveryPortfolioDetails.ManagementMarketUnits.filter((x: any) => x.ParentId == componentRef.DeliveryPortfolioDetails.ManagementMarketId);
      componentRef.DeliveryPortfolioDetails.MMU = marketUnit;
    }
  }

  showInvolvedFactories(componentRef) {
    var involvedFactoriesList, selectedInvolvedFactory;
    if (componentRef.scopeDetails) {
     

      if (componentRef.endToEndType == "I" || componentRef.endToEndType == "O") {
        involvedFactoriesList = componentRef.scopeDetails.InvolvedFactories.filter((x: any) => x.ParentId == null && x.DeliveryCenterId == null);
      }
      else {
        involvedFactoriesList = componentRef.scopeDetails.InvolvedFactories;
      }
                 
      selectedInvolvedFactory = componentRef.scopeDetails.InvolvedFactoryInputs;
      var gridData = Enumerable.from(componentRef.scopeDetails.InvolvedFactories).join(
        selectedInvolvedFactory, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();
      componentRef.involvedfactoriesgriddata = gridData;

    }
    else {
      involvedFactoriesList = componentRef.DeliveryPortfolioDetails.InvolvedFactories.filter((x: any) => x.ParentId == null && x.DeliveryCenterId == null);
      selectedInvolvedFactory = componentRef.DeliveryPortfolioDetails.InvolvedFactoryInputs;
      var gridData = Enumerable.from(involvedFactoriesList).join(
        selectedInvolvedFactory, (dtl: any) => dtl.Id, (sdt: any) => sdt, (dtl: any, sdt: any) => new Object({ Id: dtl.Id, Name: dtl.Name })).toArray();
      componentRef.involvedfactoriesgriddata = gridData;
    }

    const initialState = {
      title: "Involved Factories", label: "Involved Factory",
      gridData: gridData,
      masterData: involvedFactoriesList
    };
    //var modelservice = componentRef.modalService.show(ManageDemographicsComponent, {
    //  initialState,
    //  backdrop: "static",
    //  keyboard: false
    //});
    $(".modal-dialog").addClass('custom-modal');

    //modelservice.content.manageDemographicOutput.subscribe(result => {
    //  if (componentRef.scopeDetails) {
    //    componentRef.scopeDetails.InvolvedFactory = result.gridData.map(x => x.Name).join();
    //    componentRef.scopeDetails.InvolvedFactoryInputs = result.gridData.map(({ Id }) => Id);
    //  }
    //  else {
    //    componentRef.DeliveryPortfolioDetails.InvolvedFactory = result.gridData.map(x => x.Name).join();
    //    componentRef.DeliveryPortfolioDetails.InvolvedFactoryInputs = result.gridData.map(({ Id }) => Id);
    //  }

    //  if (componentRef.CreatePage) {
    //    componentRef.deliveryForm.form.markAsDirty();
    //  } else {
    //    //componentRef.profileForm.form.markAsDirty();
    //    componentRef.scopeForm.form.markAsDirty();
    //  }

    //});

  }


  SubgroupCategoryChange(componentRef) {
    componentRef.DeliveryPortfolioDetails.SelectedDeliverySubgroup = null;
  }

  SubGroupChange(componentRef) {
    if (componentRef.DeliveryPortfolioDetails.ServicesUsedInputs.indexOf(componentRef.DeliveryPortfolioDetails.SelectedDeliverySubgroup) >= 0) {
      this.sharedService.openAlertPopup('The "Subgroup" and "Services Used" chosen are the same. Please use either a different "SubGroup" or "Services Used" to proceed.', window.scrollY);
      componentRef.DeliveryPortfolioDetails.SelectedDeliverySubgroup = componentRef.deliverysubgroup;
      return false;
    }
    else {
      componentRef.deliverysubgroup = componentRef.DeliveryPortfolioDetails.SelectedDeliverySubgroup;
    }
  }

  avanadeChannelChange(componentRef) {

    componentRef.DeliveryPortfolioDetails.AvanadeGSAId = null;

    if (componentRef.DeliveryPortfolioDetails.AvanadeChannelId == 0) {
      componentRef.DeliveryPortfolioDetails.AvanadeGeographicUnitId = null;
      componentRef.DeliveryPortfolioDetails.AvanadeCountryId = null;
    }

  }

  avanadeGeoUnitChange(Id, componentRef, eventflag?) {
    if (Id != undefined && eventflag) {
      componentRef.DeliveryPortfolioDetails.AvanadeCountryId = null;
    }
    var mappingunitandcountrylist = componentRef.DeliveryPortfolioDetails.AvandeGeographics;
    var avanadecountrylist = componentRef.DeliveryPortfolioDetails.AvandeCountries;
    var avanadegeolist = componentRef.DeliveryPortfolioDetails.AvandeGeographicAreas;
    var avanadegeounitlist = componentRef.DeliveryPortfolioDetails.AvandeGeographicUnits;
    var query = Enumerable.from(mappingunitandcountrylist).where((x: any) => x.AvanadeGeographicUnitId == Id).groupBy(
      null, // (identity)
      null, // (identity)
      (x: any) => new Object({ Id: x.AvanadeCountryId, name: x.AvanadeCountryName }),
      // "{ Id: $.AvanadeCountryId, Name: $.AvanadeCountryName }",
      null
    ).toArray();
    var avanadecountrydropdown = Enumerable.from(avanadecountrylist).join(
      query, (x: any) => x.Id, (y: any) => y.Id, (x: any, y: any) => new Object({ Id: x.Id, Name: x.Name })).toArray();//TODO Need testing
    var avanadegeounit: any = Enumerable.from(avanadegeounitlist).join(
      avanadegeolist, (dtl: any) => dtl.ParentId, (sdt: any) => sdt.Id, (dtl: any, sdt: any) => new Object({ dtl: dtl, sdt: sdt })).where((w: any) => w.dtl.Id == Id).select((s: any) => new Object({ Name: s.sdt.Name })).firstOrDefault();
    componentRef.AvanadeGeoCountryLoad = avanadecountrydropdown;
    componentRef.DeliveryPortfolioDetails.AvanadeGeographicAreaName = (Id == null) ? "" : avanadegeounit.Name;
    if (Id == null)
      componentRef.DeliveryPortfolioDetails.AvanadeCountryId = null;
  }

  ValidateDemographicTabHighlight(componentRef) {
    if (!componentRef.CreatePage) {
      if ((componentRef.sessionStorage.isValidEndDate != null && componentRef.sessionStorage.isValidEndDate != undefined) &&
        (componentRef.sessionStorage.isValidEndToEndOverallDeliveryLead != null && componentRef.sessionStorage.isValidEndToEndOverallDeliveryLead != undefined)) {
        if (componentRef.endDateValidation)
          componentRef.endDateValidation.IsValidEndDate = componentRef.sessionStorage.isValidEndDate;
        componentRef.ProfileTabHighlight = cloneDeep(!componentRef.sessionStorage.isValidEndDate);
        componentRef.IsValidEndToEndOverallDeliveryLead = componentRef.sessionStorage.isValidEndToEndOverallDeliveryLead;
        componentRef.keyContactTabHighlight = cloneDeep(!componentRef.sessionStorage.isValidEndToEndOverallDeliveryLead);
        this.errorTabHighlight.emit();
      }
      else {
        componentRef.tileService.validateProfile(false).subscribe((response: any) => {
          componentRef.sessionStorage.isValidEndDate = response.IsEndDateValid;
          if (componentRef.endDateValidation)
            componentRef.endDateValidation.IsValidEndDate = response.IsEndDateValid;
          componentRef.ProfileTabHighlight = cloneDeep(!response.IsEndDateValid);
          componentRef.sessionStorage.isValidEndToEndOverallDeliveryLead = response.IsEndToEndOverallDeliveryLeadValid;
          componentRef.IsValidEndToEndOverallDeliveryLead = response.IsEndToEndOverallDeliveryLeadValid;
          componentRef.keyContactTabHighlight = cloneDeep(!response.IsEndToEndOverallDeliveryLeadValid);
          this.errorTabHighlight.emit();
        });
      }
    }
    else {
      this.errorTabHighlight.emit();
    }
  }

  focusErrorField(formName) {  
    return new Promise(done => {
      setTimeout(() => {
        $("[name=" + formName + "]").find('.has-error')[0].focus();
      }, 0);
    });
  }

 


}

import {  Component, OnChanges, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorage } from '../../core/context/sessionstorage';
import { TileService } from '../../core/services/tile.service';
import { enumArea, enumRoleType, enumDeployRegion, enumPath, enumTileShortNames, enumSubmissionType, enumProfileTab, enumModuleAction } from '../../core/context/enum';
import { TileConstants } from '../../core/context/constants';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { UserService } from '../../core/services/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AnnouncementdialogComponent } from '../../welcome/announcementdialog.component';
import { NotificationsAlertsComponent } from '../../welcome/notifications-alerts.component';
import { ScopeSelectorComponent } from '../scope.selector.component/scope.selector.component';
import cloneDeep from 'lodash/cloneDeep';
import { UnsavedChangesService } from '../../core/services/unsaved.changes.service';
import { Location } from "@angular/common";
import { ApplicationLoggingService } from '../../core/services/application.logging.service';
import { DemographicsService } from '../../core/services/demographics.service';
import { BroadCastService } from '../../core/services/broadcast.service';
import { Subscription } from 'rxjs';

var $ = (<any>window).$;
declare var userNotification: any;
declare var window: any;
@Component({
  selector: 'header-section',
  templateUrl: './header.component.html',
  providers: [TileService, BsModalService, ApplicationLoggingService, DemographicsService]
})

export class HeaderComponent implements OnInit {
  constructor(private router: Router, private tileService: TileService, private sessionStorage: SessionStorage,
    private activatedRoute: ActivatedRoute, private userService: UserService, private applicationLoggingService: ApplicationLoggingService, private location: Location,
    private unsavedChangesService: UnsavedChangesService, private msalService: MsalService, private modalService: BsModalService, private bsModalRef: BsModalRef,
    private demographicsService: DemographicsService, private broadCastService: BroadCastService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.ScopeSelectorVisibleSubscription = this.broadCastService.getData().subscribe((response) => {
      if (response != null) {
        this.ScopeSelecorVisible(response);
      }
    });
    this.ScopeSelectorTitleSubscription = this.broadCastService.refreshScopeSelectorTitle().subscribe((response) => {
      if (response != null) {
        this.setTitle(response);        
        
      }
    });
    this.ProfileCompletionSubscription = this.broadCastService.refreshProfileCompletion().subscribe((response) => {
      if (response != null) {
        this.profileCompletedStatus(response);
      }
    });
    this.TeamListSubscription = this.broadCastService.refreshTeamList().subscribe((response) => {
      if (response != null && this.endToEndCMSTeams!= undefined) {
        this.endToEndCMSTeams.TMS = response.teamList.TMS;
      }
    });
  }
 
  tiles: any;
  enterpriseId = this.sessionStorage.enterpriseId;
  isPortfolioTabVisible = false;
  isManageTabVisible = false;
  isTaskTabVisible = false;
  isAdminTabVisible = false;
  isSupportTabVisible = false;
  isTaskActive = false;
  isPortfolioActive = false;
  isManageActive = false;
  isAdminActive = false;
  isSupportActive = false;
  deployRegionHeader: string;
  redirectValue = "E2E";
  redirectE2EId = this.sessionStorage.endToEndUId;
  path = this.router.url;
  isCMMIPage: boolean;
  isOpportunityCreator = false;
  isInitiativeCreator = false;
  isSupportUser = false;
  currentDay: any = (new Date()).getDay();
  logOutUrl: string = environment.logOutUrl;
  releaseNotesPreferenceValue: boolean;
  openSlide: boolean;
  modalRef_announcement: BsModalRef;
  showMandatoryAlert: any;
  isDisableAlertNavigation: any;
  modalRef: BsModalRef;
  endToEndName: string;
  scopeTitle: string;
  endToEndCMSTeams: any;
  endToEndCMSTeams1: any;
  teamScopeSelector: any = {};
  isDisableTeamScopeSelector: boolean = ((this.location.path().indexOf('cmmi') > -1) || (this.location.path().indexOf('avanadeeac') > -1) || (this.location.path().indexOf('deliveryexcellence') > -1) || (this.location.path().indexOf('otherconfigsettings') > -1)) ? true : false;
  isScopeSelectorVisible: boolean = !((this.location.path().indexOf('portfolio') > -1) || (this.location.path().indexOf('admin') > -1) || (this.location.path().indexOf('opportunity') > -1) || (this.location.path().indexOf('internalinitiative') > -1) || (this.location.path().indexOf('configuration') > -1) || (this.location.path().indexOf('support') > -1) || (this.location.path().indexOf('security') > -1) || (this.location.path().indexOf('releasenotes') > -1) || (this.location.path().indexOf('developementsupport') > -1) || (this.location.path().indexOf('matrixuserguide') > -1) || (this.location.path().indexOf('tmsuserguide') > -1) || (this.location.path().indexOf('cmsuserguide') > -1));
  isScopeSelectorDropDownVisible: boolean = ((this.location.path().indexOf('deliverycenter') > -1) || (this.location.path().indexOf('avanadeeac') > -1) || (this.location.path().indexOf('deliveryexcellence') > -1)) ? false : true;
  ScopeSelectorVisibleSubscription: Subscription;
  ScopeSelectorTitleSubscription: Subscription;
  ProfileCompletionSubscription: Subscription;
 TeamListSubscription: Subscription;
  IsProfileCompletedStatus: boolean  = this.sessionStorage.IsProfileCompleted; 
  clientName: any;
  endtoendName: any;
  wsName: any;
  cmsName: any;
  ngOnInit() {

    if (Boolean(this.sessionStorage.endToEndUId)) {
      this.getTiles();
      this.isScopeSelectorVisible = !((this.location.path().indexOf('portfolio') > -1) || (this.location.path().indexOf('admin') > -1) || (this.location.path().indexOf('opportunity') > -1) || (this.location.path().indexOf('internalinitiative') > -1) || (this.location.path().indexOf('configuration') > -1) || (this.location.path().indexOf('support') > -1) || (this.location.path().indexOf('security') > -1) || (this.location.path().indexOf('releasenotes') > -1) || (this.location.path().indexOf('developementsupport') > -1) || (this.location.path().indexOf('matrixuserguide') > -1) || (this.location.path().indexOf('tmsuserguide') > -1) || (this.location.path().indexOf('cmsuserguide') > -1));
    }
    this.isAdminTabVisible = this.sessionStorage.roleTypeCode == 'AA' || this.sessionStorage.roleTypeCode == 'AV' || this.sessionStorage.roleTypeCode == 'AC' ? true : false;
    this.isSupportTabVisible = !this.isAdminTabVisible && this.sessionStorage.customRoleTypeCode && (this.sessionStorage.customRoleTypeCode.filter((x: any) => (x == enumRoleType.OpportunityCreator) || (x == enumRoleType.InitiativeCreator))[0]) ? true : false;
    this.isCMMIPage = this.path.indexOf(enumPath.CMMI) > -1;
    this.setUpEULabel(this.path);
    this.SetupOppInitiCreator();
    this.setTabVisibility();
    this.setActiveTab(this.path);
    this.endToEndName = this.sessionStorage.endToEndName;
    this.loadTeams();
    this.setProfileCompletedStatus();
    setTimeout(() => {
      window.modalComponent();
      window.TooltipFunc();
    }, 2000);
   
    var title = "";
    this.clientName = this.sessionStorage.clientName;
    this.endtoendName = this.sessionStorage.endToEndName;
    this.cmsName = this.sessionStorage.cmsName;
    this.wsName = this.sessionStorage.workstreamName;
    title = this.sessionStorage.clientName;
    title =  this.sessionStorage.clientName;
    title = this.sessionStorage.endToEndName ? title + " | " + this.sessionStorage.endToEndName : title;
    title = this.sessionStorage.cmsName ? title + " | " + this.sessionStorage.cmsName : title;
    title = this.sessionStorage.workstreamName ? title + " |" + this.sessionStorage.workstreamName   : title;
    this.scopeTitle = title;    
    this.isScopeSelectorVisible = ((this.location.path().indexOf('portfolio') > -1) || (this.location.path().indexOf('admin') > -1) || (this.location.path().indexOf('opportunity') > -1) || (this.location.path().indexOf('internalinitiative') > -1) || this.location.path().indexOf('configuration') > -1 || this.location.path().indexOf('support') > -1 || this.location.path().indexOf('security') > -1 || this.location.path().indexOf('releasenotes') > -1 || this.location.path().indexOf('matrixuserguide') > -1 || this.location.path().indexOf('tmsuserguide') > -1 || this.location.path().indexOf('cmsuserguide') > -1) ? false : true;
    this.isScopeSelectorDropDownVisible = ((this.location.path().indexOf('deliverycenter') > -1) || (this.location.path().indexOf('avanadeeac') > -1) || (this.location.path().indexOf('deliveryexcellence') > -1)) ? false : true;
    setTimeout(() => {
      window.dropdownComponent();
    }, 2000);
  }
  ngOnDestroy() {
    this.ScopeSelectorVisibleSubscription.unsubscribe();
    this.ScopeSelectorTitleSubscription.unsubscribe();
    this.ProfileCompletionSubscription.unsubscribe();
    this.TeamListSubscription.unsubscribe();
  }

  setTitle(response) {    
    this.clientName = this.sessionStorage.clientName;
    this.endtoendName = this.sessionStorage.endToEndName;
    this.cmsName = this.sessionStorage.cmsName;
    this.wsName = this.sessionStorage.workstreamName;
   this.endToEndName = response.endToEndName;
    this.scopeTitle = response.scopeTitle;    
    this.teamScopeSelector.teamId = response.tmsName;    
    if (this.sessionStorage.tmsName) {
   
      this.teamScopeSelector.teamId = this.sessionStorage.teamId;
      this.teamScopeSelector.teamUId = this.sessionStorage.teamUId;
     
    }
    else {
      this.teamScopeSelector.teamId = this.sessionStorage.teamId = 0;
      this.teamScopeSelector.teamUId = this.sessionStorage.teamUId = "";
      this.sessionStorage.tmsName = "";
    }
    window.TooltipFunc();
  }
  profileCompletedStatus(status) {
    this.IsProfileCompletedStatus = status;
  }
  setProfileCompletedStatus() {
      this.demographicsService.getInitiateProfile(this.sessionStorage.endToEndId).subscribe((response: any) => {

        if (response && response.DeliveryProfileMandatoryAttributeSet == 0) {
          this.sessionStorage.IsProfileCompleted = false;
          this.IsProfileCompletedStatus = false;
        } else {
          this.sessionStorage.IsProfileCompleted = true;
          this.IsProfileCompletedStatus = true;
        }
      });

  }
  DisplayTab() {
    var clientCountForCardinal = this.sessionStorage.cardinalClients.filter(x => x.MasterClientId == this.sessionStorage.clientId);
    if (clientCountForCardinal.length == 0) {
      this.isSupportTabVisible = this.sessionStorage.isSupportTabVisible;
      this.isAdminTabVisible = this.sessionStorage.isAdminTabVisible;
    }
  }
  getTiles() {
    this.tileService.getTiles(enumArea.HelpSelection)
      .subscribe((tiles: any) => {
        this.tiles = tiles;
        this.configureReleaseNotesAnnouncement();
        this.ShowUserNotificationPopup();
        setTimeout(() => {
          window.TooltipFunc();
        }, 0);
      });
  }
  RefreshTeamScopeSelector() {
    this.loadTeams();
  }
  initializeUserNotificationContent(): void {
    const content = {
      DataSourceUId: "00100000-0010-0000-0000-000000000000",
      TemplateUId: "00200000-0010-0000-0000-000000000000",
      ServiceUrl: '/assets/data/UserNotification.json',
      ActiveLanguage: sessionStorage.getItem('Culture'),
      LoggedInUserId: sessionStorage.getItem('enterpriseId')
    }
  userNotification.init(content);
  }

  ShowUserNotificationPopup() {
    this.initializeUserNotificationContent();
    setTimeout(function () {
      $('#navbar-menu').click(); // temporary fix for page not refreshed post token generation
    }, 2000);
  }

  openTermsOfUsePopUp(tile) {
    if (tile.ShortName == enumTileShortNames.TermsOfUse)
        window.open(tile.Url, tile.Target, 'width=480,height=600');
  }

  setUpEULabel(path: string) {
    if (!(path.indexOf(enumPath.Portfolio) > -1 || path.indexOf(enumPath.Admin) > -1 || path.indexOf(enumPath.Opportunity) > -1 ||
      path.indexOf(enumPath.InternalInitiative) > -1 || path.indexOf(enumPath.Configuration) > -1 || path.indexOf(enumPath.Security) > -1 ||
      path.indexOf(enumPath.Support) > -1 || path.indexOf(enumPath.ReleaseNotes) > -1)) {
      this.deployRegionHeader = this.sessionStorage.deployRegion && this.sessionStorage.deployRegion == enumDeployRegion.EU ? this.sessionStorage.deployRegion + " - Account" : "";
    }
  }

  setActiveTabOnClick(path: string) {
    //if (TileConstants.ProfileTiles.indexOf(path.split('/')[2]) > -1) {
    //}
    //else if (path.indexOf(enumPath.AvanadeEACForecast) > -1) {
    //}
    //else if (path.indexOf(enumPath.DeliveryExcellence) > -1) {
    //}   
    this.redirectonOnTabClick(path);
    this.setActiveTab(path);
    this.scopeSelectorDisplay(path);
  }

  redirectonOnTabClick(path: string) {
    if (path.indexOf(enumPath.Admin) > -1 || path.indexOf(enumPath.Support) > -1) {
      this.router.navigate(['./' + path], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['./' + path, this.redirectValue, this.redirectE2EId], { relativeTo: this.activatedRoute });
    }
    this.setUpEULabel(path);
  }

  // on function for selecting tab  based on role 
  // after guided tour ends.

  SetActiveTabSelection(event) {
    var path = event.page;
    this.setActiveTab(path);
    this.setUpEULabel(path);
  }

  setActiveTab(path: string) {
    this.SetupOppInitiCreator();
    this.isTaskActive = false;
    this.isPortfolioActive = false;
    this.isManageActive = false;
    this.isAdminActive = false;
    this.isSupportActive = false;
    if (path.indexOf(enumPath.Tasks) > -1) {
      this.isTaskActive = true;
    }
    if ((path.indexOf(enumPath.Portfolio) > -1 && path.indexOf('deliveryportfolio') <= -1) || (path.indexOf(enumPath.Configuration) > -1 && path.indexOf(enumPath.TileConfiguration) < 0 )) {
      this.isPortfolioActive = true;
    }
    if ((path.indexOf(enumPath.Manage) > -1)  || TileConstants.ProfileTiles.indexOf(path.split('/')[2]) > -1
      || (path.indexOf(enumPath.CMMI) > -1) || (path.indexOf(enumPath.AvanadeEACForecast) > -1) || (path.indexOf(enumPath.ChildTiles) > -1) || (path.indexOf('matrixuserguide') > -1) || (path.indexOf('tmsuserguide') > -1) || (path.indexOf('cmsuserguide') > -1) || (path.indexOf('otherconfiguserguide') > -1) || (path.indexOf('thresholduserguide') > -1) || (path.indexOf('metricsuserguide') > -1)
      || (path.indexOf(enumPath.PlatformAccess) > -1) || (path.indexOf(enumPath.DeliveryExcellence) > -1) || TileConstants.ConfigTiles.indexOf(path.split('/')[2]) > -1) {
      this.isManageActive = true;
    }
    if ((path.indexOf(enumPath.Admin) > -1) || (path.indexOf(enumPath.Opportunity) > -1) || (path.indexOf(enumPath.InternalInitiative) > -1)
      || (path.indexOf(enumPath.Security) > -1) || (path.indexOf(enumPath.ReleaseNotes) > -1)
      || (path.indexOf(enumPath.DevelopmentSupport) > -1) || (path.indexOf(enumPath.TileConfiguration) > -1)) {
      this.isAdminActive = true;
    }
    if ((path.indexOf(enumPath.Support) > -1) || (((this.isOpportunityCreator && path.indexOf(enumPath.Opportunity) > -1) || (this.isInitiativeCreator && path.indexOf(enumPath.InternalInitiative) > -1)) && this.sessionStorage.roleTypeCode.indexOf('A') != 0)) {
      this.isSupportActive = true;
    }
  }

  setTabVisibility() {
    this.isPortfolioTabVisible = true;
    this.isManageTabVisible = true;
    this.isTaskTabVisible = true;

    if (this.sessionStorage.roleTypeCode == enumRoleType.TeamMember) {
      this.isPortfolioTabVisible = false;
      this.isManageTabVisible = false;
    }
    else if (this.isSupportUser && (this.isOpportunityCreator || this.isInitiativeCreator)) {
      this.isPortfolioTabVisible = false;
      this.isManageTabVisible = false;
      this.isTaskTabVisible = false;
    }
    if (environment.restrictedInstance) {
      this.isPortfolioTabVisible = false;
    }
    if (this.sessionStorage.cardinalClients == null) {
      this.userService.getClientAccess(this.enterpriseId).subscribe((response: any) => {
        this.sessionStorage.cardinalClients = response.Clients;
        var clientCountForCardinal = response.Clients.filter(x => x.MasterClientId == this.sessionStorage.clientId).length;
        if (response.ClientIndicator == 'Y') {
          this.sessionStorage.clientAccessEnabled = true;
        }
        if (clientCountForCardinal > 0) {
          this.sessionStorage.isAdminTabVisible = false;
        }
        this.CardinalUserTabDisplay();
      });
    }
    else {
      this.CardinalUserTabDisplay();
    }
  }
  CardinalUserTabDisplay() {
    var clientCountForCardinal = this.sessionStorage.cardinalClients.filter(x => x.MasterClientId == this.sessionStorage.clientId);
    if (this.sessionStorage.clientAccessEnabled == true && clientCountForCardinal > 0) {
      this.isPortfolioTabVisible = false;
      this.isTaskTabVisible = false;
      this.isAdminTabVisible = false;
      this.isSupportTabVisible = false;
    }
  }

  SetupOppInitiCreator() {
    this.isOpportunityCreator = this.sessionStorage.customRoleTypeCode && this.sessionStorage.customRoleTypeCode.filter((x: any) => x == enumRoleType.OpportunityCreator)[0] ? true : false;
    this.isInitiativeCreator = this.sessionStorage.customRoleTypeCode && this.sessionStorage.customRoleTypeCode.filter((x: any) => x == enumRoleType.InitiativeCreator)[0] ? true : false;

    if (this.isOpportunityCreator || this.isInitiativeCreator) {
      if (this.sessionStorage.roleTypeCode && this.sessionStorage.roleTypeCode[0]) {
        this.isSupportUser = (this.sessionStorage.roleTypeCode.indexOf(enumRoleType.OpportunityCreator) > -1) || (this.sessionStorage.roleTypeCode.indexOf(enumRoleType.InitiativeCreator) > -1) ? true : false;
      }
    }
  }

  logOut() {
    localStorage.clear();
    sessionStorage.clear();
    this.msalService.logout();
    window.location.href = environment.logOutUrl;
  }
  UsageLoggingUserHelp() {

  }

  trackByTileId(index: number, item: any): any {
    return item.TileId;
  }

  // Welcome page migration

  configureReleaseNotesAnnouncement() {
    this.userService.getReleaseNoteUserPreference(this.sessionStorage.enterpriseId).subscribe((response: any) => {
      this.releaseNotesPreferenceValue =  response;
      $('#mainContent').click(); // temporary fix for page not refreshed post token generation
      this.openSlide = (this.releaseNotesPreferenceValue == false) ? true : false;
      if (!this.releaseNotesPreferenceValue && !this.sessionStorage.isReleaseAnnouncementClosed)
        this.toggleSlide();
    }, function (error) {
      this.releaseNotesPreferenceValue = false;
      this.openSlide = true;
    });
  }

  toggleSlide() {
    this.modalRef_announcement = this.modalService.show(AnnouncementdialogComponent, {
      backdrop: "static",
      keyboard: false
    });
    $(".modal-dialog").addClass('custom-modal-announcement');
  }

  OpenNotificationsPopUp() {

    
    const initialState = { isScopeSelectorVisible: this.isScopeSelectorVisible }
    this.modalRef_announcement = this.modalService.show(NotificationsAlertsComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
    $(".modal-dialog").addClass('custom-modal-announcement');
  }

  openScopeSelecor(event) {

    //this.scopeSelectorComponent.LoadScopeSelector(false, event);
    var scopeSelectorModelRef = this.modalService.show(ScopeSelectorComponent, {

      backdrop: "static",
      keyboard: false
    });

    scopeSelectorModelRef.content.setE2EName.subscribe(result => {
      this.endToEndName = result.endToEndName;
      this.scopeTitle = result.scopeTitle;
      
    });
  }
  
  loadTeams = function () {
    
    this.userService.getTeamsByEndtoEndOrCMS().subscribe((response) => {
      if (response) {
        this.endToEndCMSTeams = response;
        this.sessionStorage.endToEndCMSTeams = response;
        this.endToEndCMSTeams = cloneDeep(this.sessionStorage.endToEndCMSTeams);
        this.broadCastService.broadcastTeamList({ teamList: this.sessionStorage.endToEndCMSTeams });
        if (this.endToEndCMSTeams.TMS.length == 0)
          this.isDisableTeamScopeSelector = true;

        this.teamScopeSelector.teamId = this.sessionStorage.teamId;

        this.endToEndCMSTeams.SelectedTMS = this.endToEndCMSTeams.TMS.filter(x => x.Id === this.sessionStorage.teamId);
        if (this.endToEndCMSTeams.SelectedTMS.length > 0) {
          this.endToEndCMSTeams.SelectedTMS[0].IsSelected = true;
          for (var i = 0; i < this.endToEndCMSTeams.TMS.length; i++) {
            if (this.endToEndCMSTeams.TMS[i].Id === this.sessionStorage.teamId) {
              this.endToEndCMSTeams.TMS[i].IsSelected = true;
            }
            else {
              this.endToEndCMSTeams.TMS[i].IsSelected = false;
            }
          }

        }



        var cmmiTeamVisible = this.location.path().indexOf('cmmi') > -1 ? true : false;
        if (cmmiTeamVisible) {
          this.IsDisableTeamScopeSelector == true;
          return false;
        }
        else {
          this.endToEndCMSTeams = cloneDeep(this.sessionStorage.endToEndCMSTeams)
          if (this.sessionStorage.teamId) {
            this.teamScopeSelector.teamId = this.sessionStorage.teamId;
            var selectedTeam = this.endToEndCMSTeams.TMS.filter(x => x.Id === this.sessionStorage.teamId);
            if (selectedTeam) {
              selectedTeam.IsSelected = true;
            }
          }
          else {
            this.teamScopeSelector.teamId = 0;
          }
        }
      }
    });
  }
 
  TeamChange(tmsId: any, event?: any) {
    if (event != null) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode !== 13) { // code for ENTER key
        return;
      }
    }
    if (this.splitURL(this.router.url) == "/mywizard/profile") {
      this.unsavedChangesService.broadcast("profile");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/scope") {
      this.unsavedChangesService.broadcast("scope");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/keycontacts") {
      this.unsavedChangesService.broadcast("keycontacts");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/clientstructure") {
      this.unsavedChangesService.broadcast("clientstructure");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    else if (this.splitURL(this.router.url) == "/mywizard/teamstructure") {
      this.unsavedChangesService.broadcast("teamstructure");
      if (this.sessionStorage.isUserAlert) {
        return false;
      }
    }
    this.endToEndCMSTeams.SelectedTMS = this.endToEndCMSTeams.TMS.filter(x => x.Id === tmsId)[0];
    if (tmsId) {
      //this.endToEndCMSTeams.SelectedTMS = selectedTMS;
      //selectedTMS.IsSelected = true;
      this.sessionStorage.teamId = this.teamScopeSelector.teamId = this.endToEndCMSTeams.SelectedTMS.Id;
      this.sessionStorage.teamUId = this.teamScopeSelector.teamUId = this.endToEndCMSTeams.SelectedTMS.UId;
      this.sessionStorage.tmsName = this.endToEndCMSTeams.SelectedTMS.Name;
    }
    else {
      this.sessionStorage.teamId = this.teamScopeSelector.teamId = 0;
      this.sessionStorage.teamUId = this.teamScopeSelector.teamUId = "";
      this.sessionStorage.tmsName = "";
    }   
    this.applicationLoggingService.applicationUsageLogging(enumModuleAction.ScopeSelector, null, null).subscribe();
    this.applyTeam();
    this.SubmissionType();
  };

  splitURL(url: string) {
    var hashes: string[] = url.split('/');
    var urlMyWizard = hashes[1];  // It will give mywizard from URL
    var urlPage = hashes[2];  // It will give pages like manage, admin and tasks etc... from URL

    return '/' + urlMyWizard + '/' + urlPage;
  }

  applyTeam() {
    this.sessionStorage.profileTab = enumProfileTab.TeamStructure;
    if (this.sessionStorage.teamId != 0 && (this.splitURL(this.router.url) == "/mywizard/profile" || this.splitURL(this.router.url) == "/mywizard/scope" || this.splitURL(this.router.url) == "/mywizard/keycontacts" || this.splitURL(this.router.url) == "/mywizard/clientstructure" || this.splitURL(this.router.url) == "/mywizard/teamstructure" || this.splitURL(this.router.url) == "/mywizard/matrixstructure")) {
      this.refreshPage("/mywizard/teamstructure", this.getParentTileId(this.router.url));
    }
    else if (this.sessionStorage.teamId == 0 && !(this.sessionStorage.cmsName || this.sessionStorage.workstreamName) && (this.splitURL(this.router.url) == "/mywizard/profile" || this.splitURL(this.router.url) == "/mywizard/scope" || this.splitURL(this.router.url) == "/mywizard/keycontacts" || this.splitURL(this.router.url) == "/mywizard/clientstructure" || this.splitURL(this.router.url) == "/mywizard/teamstructure" || this.splitURL(this.router.url) == "/mywizard/matrixstructure")) {
      this.refreshPage("/mywizard/profile", this.getParentTileId(this.router.url));
    }
    else if ((this.sessionStorage.cmsName || this.sessionStorage.workstreamName) && (this.splitURL(this.router.url) == "/mywizard/profile" || this.splitURL(this.router.url) == "/mywizard/scope" || this.splitURL(this.router.url) == "/mywizard/keycontacts" || this.splitURL(this.router.url) == "/mywizard/clientstructure" || this.splitURL(this.router.url) == "/mywizard/teamstructure" || this.splitURL(this.router.url) == "/mywizard/matrixstructure")) {
      this.refreshPage("/mywizard/clientstructure", this.getParentTileId(this.router.url));
    }
    else {
      this.refreshPage(this.splitURL(this.router.url), this.getParentTileId(this.router.url));
    }
  }
  SubmissionType() {
    if (this.sessionStorage.workstreamId) {
      if (this.sessionStorage.teamId) {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.Team;
        this.sessionStorage.profileTab = enumProfileTab.TeamStructure;

      }
      else {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.Workstream;
        this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
      }
    }
    else if (this.sessionStorage.clientWMSId) {
      if (this.sessionStorage.teamId) {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.CMSTMS;
        this.sessionStorage.profileTab = enumProfileTab.TeamStructure;

      }
      else {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.ClientWMS;
        this.sessionStorage.profileTab = enumProfileTab.ClientStructure;
      }
    }
    else {
      if (this.sessionStorage.teamId) {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.Team;
        this.sessionStorage.profileTab = enumProfileTab.TeamStructure;
      }
      else {
        this.sessionStorage.selectedSubmissionType = enumSubmissionType.EndToEnd;
        this.sessionStorage.profileTab = enumProfileTab.Profile;
      }
    }

  }



  refreshPage(state: string, parentTileId: number = null) {
    if (parentTileId) {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([state, parentTileId, 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
    else {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([state, 'E2E', this.sessionStorage.endToEndUId], { relativeTo: this.activatedRoute });
    }
  }

  getParentTileId(url: string) {
    var hashes: string[] = url.split('/');
    var id = hashes[3];
    if (/^\d+$/.test(id)) {
      return parseInt(id);
    } else {
      return null;
    }
  }

  scopeSelectorDisplay(path) {
    this.isScopeSelectorVisible = !((path.indexOf('portfolio') > -1) || (path.indexOf('admin') > -1) || (path.indexOf('opportunity') > -1) || (path.indexOf('internalinitiative') > -1) || path.indexOf('configuration') > -1 || path.indexOf('support') > -1 || path.indexOf('security') > -1 || path.indexOf('releasenotes') > -1);
    this.isDisableTeamScopeSelector = ((path.indexOf('cmmi') > -1) || (path.indexOf('avanadeeac') > -1) || (path.indexOf('deliveryexcellence') > -1) || (path.indexOf('platformaccess') > -1)) ? true : false;
    this.isScopeSelectorDropDownVisible = ((path.indexOf('deliverycenter') > -1) || (path.indexOf('avanadeeac') > -1) || (path.indexOf('deliveryexcellence') > -1)) ? false : true;
  
  }

  ScopeSelecorVisible(scopeSelectorAttributes: any) {
    this.isScopeSelectorVisible = scopeSelectorAttributes.isScopeSelectorVisible;
    this.isScopeSelectorDropDownVisible = scopeSelectorAttributes.isScopeSelectorDropDownVisible;
  //  this.isSetAsDefaultVisible = scopeSelectorAttributes.isSetAsDefaultVisible != undefined ? scopeSelectorAttributes.isSetAsDefaultVisible : false;
    // this.isDisableTeamScopeSelector = scopeSelectorAttributes.isDisableTeamScopeSelector != undefined ? scopeSelectorAttributes.isDisableTeamScopeSelector : false;
    this.isDisableTeamScopeSelector = scopeSelectorAttributes.isDisableTeamScopeSelector != undefined ? scopeSelectorAttributes.isDisableTeamScopeSelector : false;
   
  }
  

}



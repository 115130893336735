<form name="profileform" *ngIf="profileDetails" id="profileForm" #profileform="ngForm" novalidate (ngSubmit)="SaveProfile(profileform.valid,profileform)">


  <div class="c-myWizard-container">
    <div class="c-myWizard__header">
      <div class="c-myWizard__header-content-left">
        <div class="c-myWizard__header-content-head">
          <a href="javascript:;" class="icon-banner-arrow" role="button" (click)="OnBackClick()" alt="Back" aria-label="Back"></a>
          <div class="-header">Profile</div>

        </div>

        <div class="c-myWizard-text" *ngIf="!IsProfileCompleted">Complete the preliminary mandatory step to setup your delivery profile</div>
        <div class="c-myWizard-text" *ngIf="endToEndType == 'C' && IsProfileCompleted">E2Es with more than one contract association have demographics of the highest revenue contract highlighted in bold.</div>
      </div>
      <div class="c-myWizard__header-content-right">
        <download-file source="DeliveryProfileGuide"></download-file>
        <a *ngIf="endToEndType =='C' && E2eDemoGraphics.IsShowMultiContract" href="javascript:;" class="c-myWizard__header-details" role="button" data-flyoutid="contract-details" (click)="showContractInfo()">
          <img src="../assets/toolkit/images/profile/file.svg" alt="Details" role="presentation" />
          <span>Associated Contract Details</span>
        </a>
      </div>
    </div>
    <!--<div class="c-scope-error" *ngIf="ShowDominantInfo && endToEndType == 'C'">
      <ul class="c-myWizard-list -customScroll">
        <li>
          <p *ngIf="IsProfileCompleted && endToEndType != 'C'">E2Es with more than one contract association have demographics of the highest revenue contract highlighted in bold.</p>
        </li>
      </ul>
    </div>-->
    <div class="c-myWizard-form__input">
      <div class="c-myWizard-form__input-area" *ngIf="CreatePage">
        <div class="c-custom-select__wrapper -required">
          <label for="testRecord">Test Record</label>
          <search-dropdown id="testRecord"
                           name="testRecord"
                           #testRecord="ngModel"
                           aria-labelledby="TestRecord"
                           [required]="true"
                           [selectText]="'Select'"
                           [formname]="profileform"
                           [errormessage]="'Please select Test Record'"
                           [options]="TestRecordIndicator"
                           [selected]="profileDetails.TestRecordIndicator" [isShow]="true"
                           [(ngModel)]="profileDetails.TestRecordIndicator" (callback)="TestRecordChange()"
                           [disableDropdown]="IsDisableTestRecordIndicator">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'O'">
        <div class="o-input -required" *ngIf="!profileDetails.IsCMWOpportunity">
          <label for="opportunityName">Opportunity Name</label>
          <input type="text"
                 name="opportunityname"
                 #opportunityname="ngModel"
                 autocomplete="off"
                 aria-labelledby="OpportunityName"
                 maxlength="50"
                 [ngClass]="{'has-error':profileform.submitted && opportunityname.errors?.required}"
                 [required]="true" [disabled]="RoleAccess=='V' || RoleTypeCode == false"
                 value="{{profileDetails.Opportunity}}"
                 [(ngModel)]="profileDetails.Opportunity"
                 class="o-input__input"
                 placeholder="Enter Opportunity Name"
                 id="opportunityName"
                 restrictTags />
          <p aria-live="polite" class="o-input__error-text" aria-atomic="true" role="status" [ngClass]="{'is-error':profileform.submitted && opportunityname.errors?.required}">Please enter Opportunity Name</p>
        </div>
        <div class="o-input" *ngIf="!CreatePage && profileDetails.IsCMWOpportunity">
          <label for="opportunityName">Opportunity Name</label>
          <p class="o-input__input -disabled" [innerHTML]="profileDetails.Opportunity"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'I'">
        <div class="o-input">
          <label for="displayName">Preferred Display Name</label>
          <input type="text"
                 class="o-input__input"
                 name="preferredDisplayName"
                 #preferredDisplayName="ngModel"
                 aria-labelledby="PreferredDisplayName"
                 value="{{profileDetails.DisplayName}}"
                 [disabled]="RoleAccess=='V'"
                 maxlength="50"
                 [(ngModel)]="profileDetails.DisplayName"
                 placeholder="{{Contract| slice:0:10}}"
                 id="displayName" />
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input">
          <label for="client">Client</label>
          <p aria-labelledby="Client" class="o-input__input -disabled" [innerHTML]="profileDetails.ClientName"></p>
          <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I'">
        <div class="o-input -required">
          <label for="initiativeName">Initiative Name</label>
          <input name="initiativename"
                 #initiativename="ngModel"
                 autocomplete="off"
                 aria-labelledby="InitiativeName"
                 value="{{profileDetails.Initiative}}"
                 maxlength="50"
                 [ngClass]="{'has-error':profileform.submitted && initiativename.errors?.required}"
                 [required]="true" [disabled]="RoleAccess=='V' || RoleTypeCode == false"
                 type="text"
                 [(ngModel)]="profileDetails.Initiative"
                 class="o-input__input"
                 placeholder="Enter Initiative Name"
                 id="initiativeName"
                 restrictTags />
          <p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':profileform.submitted && initiativename.errors?.required}">Please enter Initiative Name</p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'O'">
        <div class="o-input -required" *ngIf="CreatePage">
          <label for="opportunityId">Opportunity Id</label>
          <input type="text"
                 name="opportunityId"
                 #opportunityid="ngModel"
                 autocomplete="off"
                 aria-labelledby="OpportunityId"
                 value="OpportunityId"
                 maxlength="11"
                 [ngClass]="{'has-error':profileform.submitted && opportunityid.errors?.required}"
                 (paste)="OnPaste($event, 'OpportunityId')" (keypress)="KeyPressAlphanumeric($event)"
                 [required]="true"
                 [disabled]="RoleAccess=='V' || RoleTypeCode == false"
                 [(ngModel)]="profileDetails.OpportunityCode"
                 class="o-input__input"
                 placeholder="Enter Opportunity Id" />
          <p aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text" [ngClass]="{'is-error':profileform.submitted && opportunityid.errors?.required}">Please select Opportunity Id</p>
        </div>
        <div class="o-input -required" *ngIf="!CreatePage">
          <label for="opportunityId">Opportunity Id</label>
          <p class="o-input__input -disabled" [innerHTML]="profileDetails.OpportunityCode"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'O'  || endToEndType == 'I'">
        <div class="c-custom-select__wrapper" *ngIf="!profileDetails.IsCMWOpportunity">
          <label for="rde">RDE</label>
          <search-dropdown id="rde" name="rde" #rde="ngModel" [options]="DeliveryPortfolioDetails.RBE" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V' || profileDetails.IsCMWOpportunity" [isShow]="true" [labelInfo]="'RDELabel'"
                           [selected]="DeliveryPortfolioDetails.RbeId" (callback)="OnRDEChange()" [selectText]="'Select'" 
                           [(ngModel)]="DeliveryPortfolioDetails.RbeId">
          </search-dropdown>
        </div>
        <div class="c-myWizard-form__input-area" *ngIf="!CreatePage && profileDetails.IsCMWOpportunity">
          <label for="rde">RDE</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.RBEName"></p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'O' || endToEndType == 'I'">
        <div class="c-custom-select__wrapper c-input-search -required" *ngIf="(!profileDetails.IsCMWOpportunity && RoleAccess!='V')">
          <label for="customer">Customer</label>
          <div href="javascript:;" role="button" aria-expanded="false" data-toggle="dropdown" (click)="showCustomer()" aria-haspopup="true" class="c-select__selected-text" data-modalid="input-search-modal" [ngClass]="{'has-error':profileform.submitted && customer.errors?.required}">
            <input type="text" name="customer" #customer="ngModel"
                   class="" [required]="true"
                   attr.data-myw-title="{{RoleAccess=='V' ? profileDetails.Customer : 'Click here to add / change customer'}}" data-myw-toggle="tooltip" data-myw-placement="bottom"
                   placeholder="Search Customer" value="" [(ngModel)]="profileDetails.Customer">
            <i class="o-input-date__icon icon-search" aria-label="Search"></i>
          </div>
          <p class="o-input__error-text" aria-live="polite" aria-atomic="true" role="status" [ngClass]="{'is-error':profileform.submitted && customer.errors?.required}">Please select Customer</p>
         </div>
        <div class="o-input" *ngIf="(!CreatePage && profileDetails.IsCMWOpportunity) || RoleAccess=='V'">
          <label for="customer">Customer</label>
          <p class="o-input__input -disabled" [innerHTML]="profileDetails.Customer"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'O' || endToEndType == 'I'">
        <div class="o-input">
          <label for="client">Client</label>
          <p aria-labelledby="Client" class="o-input__input -disabled" [innerHTML]="profileDetails.ClientName"></p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div [ngClass]="endToEndType != 'C' ? 'o-input -required' : 'o-input'">
          <label for="company">Company</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.CompanyName"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input">
          <label for="rde">RDE</label>
          <p class="o-input__input -customScroll -textAreaInput" [innerHTML]="DeliveryPortfolioDetails.RBEName"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input">
          <label for="pricing" id="pricing">Pricing Structure</label>
          <p aria-labelledby="PricingStructure" id="pricing" name="pricingStructure" class="o-text-area__input -customScroll -textAreaInput" [innerHTML]="profileDetails.PricingStructureName"></p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div [ngClass]="endToEndType != 'C' ? 'o-input -required' : 'o-input'">
          <label for="market">Market</label>
          <p class="o-input__input -customScroll -textAreaInput" [innerHTML]="DeliveryPortfolioDetails.ManagementMarket"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div [ngClass]="endToEndType != 'C' ? 'o-input -required' : 'o-input'">
          <label for="marketUnit">Market Unit</label>
          <p class="o-input__input -customScroll -textAreaInput" [innerHTML]="DeliveryPortfolioDetails.ManagementMarketUnit"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div [ngClass]="endToEndType != 'C' ? 'o-input -required' : 'o-input'">
          <label for="clientServiceGroup">Primary Client Group</label>
          <p class="o-input__input -customScroll -textAreaInput" [innerHTML]="DeliveryPortfolioDetails.ManagementClientGroupName"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input">
          <label for="industrySegmentInfo">Industry Segment</label>
          <p class="o-input__input -customScroll -textAreaInput" [innerHTML]="DeliveryPortfolioDetails.IndustrySegement"></p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input">
          <label for="transitionHistory">Transition History</label>
          <p class="o-input__input -customScroll -textAreaInput" [innerHTML]="profileDetails.TransitionHistory"></p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType =='C' && E2eDemoGraphics.IsShowMultiContract">
        <div class="o-input">
          <label for="associatedContracts">Associated Contracts<a class="c-myWizard-select-opt" *ngIf="RoleAccess !='V'" (click)="showManagePopup(profileDetails.AssociatedContractsList,'Contract ID','profile', true)" href="javascript:void(0)" aria-label="Manage Contract IDs(Open a popup)">Manage Contract IDs</a></label>
            <p class="o-text-area__input -customScroll -textAreaInput" id="associatedContracts" aria-labelledby="Contractlbl">
              <ng-container *ngFor="let data of profileDetails.AssociatedContractsList; let l = last;">
                <span [ngClass]="{'warning-contract': data.WarningIndicator == 'Y'}" *ngIf="data.WarningIndicator == 'Y'" ng-attr-data-myw-title="{{data.WarningIndicator == 'Y'? data.Comments:''}}">{{ data.Name }}{{ l ? '' : ',' }}</span>&nbsp;
                <span *ngIf="data.WarningIndicator != 'Y'">{{ data.Name }}{{ l ? '' : ',' }}</span>&nbsp;
              </ng-container>
            </p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="(IsAdmin || CreatePage) && (endToEndType == 'O')">
        <div class="c-custom-select__wrapper">
          <label for="siFlag">SI Flag</label>
          <search-dropdown name="siFlag"
                           #siFlag="ngModel"
                           [formname]="profileform"
                           [errormessage]="'Please select SI Flag'"
                           [options]="SIFlag"
                           [selected]="profileDetails.SIFlag" [isShow]="true"
                           [(ngModel)]="profileDetails.SIFlag"
                           [disableDropdown]="profileDetails.SIFlag == 'Y' || IsSIFlagDisable || RoleAccess=='V'" (callback)="SIFlagChange()"
                           aria-labelledby="SIFlag">
          </search-dropdown>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C'" style="position:relative">
        <div class="o-input" *ngIf="RoleAccess =='V'">
          <label for="startDate">Start Date</label>
          <input *ngIf="RoleAccess =='V'" name="startdateform" #startdateform="ngModel" datais-errormessage="Select Start Date" data-described="DatePicker_StartDate" readonly type="text" [(ngModel)]="StartDate" id="StartDate" class="o-input-date__input -disabled" [disabled]="true" />
        </div>
        <div class="o-input-date -required" *ngIf="RoleAccess !='V'">
          <label for="startDate">Start Date</label>
          <input datepicker name="startdateform" #startdateform="ngModel" (onSelectDate)="StartDate = $event.value;dateChanged()" 
                 data-described="DatePicker_StartDate" [required]="true" autocomplete="off" placeholder="DD MMM YYYY"
                 [ngClass]="{'has-error':profileform.submitted && (startdateform?.errors?.required || !validateDateFormat(StartDate))}" maxlength="11" type="text"
                 [(ngModel)]="StartDate" id="StartDate" class="o-input-date__input" />
          <p class="o-input__error-text is-error" [ngClass]="{'is-error':profileform.submitted && (startdateform?.errors?.required ||!validateDateFormat(StartDate))}" *ngIf="profileform.submitted && startdateform?.errors?.required">Please select Start Date</p>
          <p class="o-input__error-text is-error" [ngClass]="{'is-error':profileform.submitted && (startdateform?.errors?.required ||!validateDateFormat(StartDate) || !startDateValue )}" *ngIf="profileform.submitted && StartDate && !validateDateFormat(StartDate) || !startDateValue">Please enter the date in "dd mmm yyyy" format</p>
          <p id="DatePicker_StartDate" class="screenReaderOnly">
            1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
            2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
            3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
          </p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input" *ngIf="RoleAccess =='V'">
          <label for="startDate">Start Date</label>
          <input *ngIf="RoleAccess =='V'" name="startdateform" #startdateform="ngModel" datais-errormessage="Select Start Date" data-described="DatePicker_StartDate" readonly type="text" [(ngModel)]="StartDate" id="StartDate" class="o-input-date__input" [disabled]="true" />
        </div>
        <div class="o-input-date -required" *ngIf="RoleAccess !='V'">
          <label for="startDate">Start Date</label>
          <input name="startdateform" #startdateform="ngModel" (onSelectDate)="StartDate = $event.value;dateChanged()" type="text" data-described="DatePicker_StartDate" [required]="true"
                 [ngClass]="{'has-error':profileform.submitted && (startdateform?.errors?.required ||  !validateDateFormat(StartDate))}"
                 maxlength="11" placeholder="DD MMM YYYY" datepicker class="o-input-date__input" id="StartDate" [(ngModel)]="StartDate" autocomplete="off" />
          <p class="o-input__error-text is-error" [ngClass]="{'is-error':profileform.submitted && (startdateform?.errors?.required || !validateDateFormat(StartDate))}" *ngIf="profileform.submitted && startdateform?.errors?.required">Please select Start Date</p>
          <p class="o-input__error-text is-error" [ngClass]="{'is-error':profileform.submitted && (startdateform?.errors?.required || !validateDateFormat(StartDate) || !startDateValue)}" *ngIf="profileform.submitted && StartDate && !validateDateFormat(StartDate) || !startDateValue">Please enter the date in "dd mmm yyyy" format</p>
          <p id="DatePicker_StartDate" class="screenReaderOnly">
            1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
            2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
            3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
          </p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C'">
        <div class="o-input" *ngIf="RoleAccess =='V'">
          <label for="endDate">End Date</label>
          <input *ngIf="RoleAccess=='V'" name="enddateform" #enddateform="ngModel" datais-errormessage="Select End Date" data-described="DatePicker_EndDate"
                 readonly [(ngModel)]="EndDate" id="EndDate" class="o-input-date__input -disabled" [disabled]="true" />
        </div>
        <div class="o-input-date -required" *ngIf="RoleAccess !='V'">
          <label for="endDate">End Date</label>
          <input datepicker (onSelectDate)="EndDate = $event.value; endDateValidation.IsValidEndDate = $event.IsValidEndDate;futureDateValidation.IsFutureEndDate = $event.IsFutureEndDate;dateChanged()"
                 name="enddateform" #enddateform="ngModel" data-described="DatePicker_EndDate" [required]="true" placeholder="DD MMM YYYY"
                 maxlength="11" [(ngModel)]="EndDate" id="EndDate" class="o-input-date__input" (change)="onChangeEndDate()" autocomplete="off"
                 [ngClass]="{'has-error':(profileDetails &&!endDateValidation.IsValidEndDate && !CreatePage)|| (profileform.submitted && (enddateform?.errors?.required || !validateDateFormat(EndDate) || !futureDateValidation.IsFutureEndDate))}" />
          <p class="o-input__error-text is-error" [ngClass]="{'is-error': (!endDateValidation.IsValidEndDate && !CreatePage) || (profileform.submitted && (enddateform?.errors?.required || !validateDateFormat(EndDate) || !futureDateValidation.IsFutureEndDate))}" *ngIf="profileDetails  && enddateform?.errors?.required && endDateValidation.IsValidEndDate">Please select End Date</p>
          <p class="o-input__error-text is-error" [ngClass]="{'is-error': (!endDateValidation.IsValidEndDate && !CreatePage) || (profileform.submitted && (enddateform?.errors?.required || !validateDateFormat(EndDate) || !futureDateValidation.IsFutureEndDate || !endDateValue))}" *ngIf="profileDetails  && EndDate && !validateDateFormat(EndDate) && endDateValidation.IsValidEndDate || !endDateValue ">Please enter the date in "dd mmm yyyy" format</p>
          <p class="o-input__error-text is-error" [ngClass]="{'is-error': (!endDateValidation.IsValidEndDate && !CreatePage) || (profileform.submitted && (enddateform?.errors?.required || !validateDateFormat(EndDate) || !futureDateValidation.IsFutureEndDate))}" *ngIf="profileDetails  && validateDateFormat(EndDate) && (!endDateValidation.IsValidEndDate || !futureDateValidation.IsFutureEndDate)">End Date is in the past</p>
          <p id="DatePicker_StartDate" class="screenReaderOnly">
            1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
            2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
            3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
          </p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input " *ngIf="RoleAccess =='V'">
          <label for="endDate">End Date</label>
          <input *ngIf="RoleAccess=='V'" name="enddateform" #enddateform="ngModel" datais-errormessage="Select End Date" data-described="DatePicker_EndDate"
                 readonly [(ngModel)]="EndDate" id="EndDate" class="o-input-date__input -disabled" [disabled]="true" />
        </div>
        <div class="o-input-date -required" *ngIf="RoleAccess !='V'">
          <label for="endDate">End Date</label>
          <input name="enddateform" #enddateform="ngModel" (onSelectDate)="EndDate = $event.value; endDateValidation.IsValidEndDate = $event.IsValidEndDate;futureDateValidation.IsFutureEndDate = $event.IsFutureEndDate;dateChanged()"
                 data-described="DatePicker_EndDate" type="text" [required]="true" autocomplete="off" placeholder="DD MMM YYYY"
                 maxlength="11" datepicker class="o-input-date__input" id="EndDate" [(ngModel)]="EndDate" (change)="onChangeEndDate()"
                 [ngClass]="{'has-error':(profileDetails &&!endDateValidation.IsValidEndDate) ||(profileform.submitted && (enddateform?.errors?.required || !validateDateFormat(StartDate) || !futureDateValidation.IsFutureEndDate ))}" />
          <p class="o-input__error-text is-error" [ngClass]="{'is-error':!endDateValidation.IsValidEndDate || (profileform.submitted && (enddateform?.errors?.required || !validateDateFormat(EndDate) || !futureDateValidation.IsFutureEndDate))}" *ngIf="profileDetails && enddateform?.errors?.required && endDateValidation.IsValidEndDate">Please select End Date</p>
          <p class="o-input__error-text is-error" [ngClass]="{'is-error':!endDateValidation.IsValidEndDate || (profileform.submitted && (enddateform?.errors?.required || !validateDateFormat(EndDate) || !futureDateValidation.IsFutureEndDate || !endDateValue))}" *ngIf="profileDetails && EndDate && !validateDateFormat(EndDate) && endDateValidation.IsValidEndDate || !endDateValue">Please enter the date in "dd mmm yyyy" format</p>
          <p class="o-input__error-text is-error" [ngClass]="{'is-error':!endDateValidation.IsValidEndDate || (profileform.submitted && (enddateform?.errors?.required || !validateDateFormat(EndDate) || !futureDateValidation.IsFutureEndDate))}" *ngIf="profileDetails && validateDateFormat(EndDate) && (!endDateValidation.IsValidEndDate || !futureDateValidation.IsFutureEndDate)">End Date is in the past</p>
          <p id="DatePicker_StartDate" class="screenReaderOnly">
            1.Ctrl + UP/DOWN/LEFT/RIGHT keys for move to previous / next day<br />
            2.PAGE UP/PAGE DOWN keys for move to previous / next month<br />
            3.SHIFT + CTRL + PAGE UP/PAGE DOWN keys for move to previous/ next year
          </p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area">
        <div class="c-custom-select__wrapper -required">
          <label for="lifecycle">Lifecycle Phase</label>
          <search-dropdown id="lifecyclePhase"
                           name="lifecyclePhase"
                           #lifecyclePhase="ngModel"
                           [options]="profileDetails.LifeCyclePhase"
                           [formname]="profileform"
                           [errormessage]="'Please select Lifecycle Phase'"
                           [disableDropdown]="RoleAccess=='V'" [required]="true" [isShow]="true"
                           [labelInfo]="'LifecyclePhase'" [(ngModel)]="profileDetails.LifeCyclePhaseId"
                           [selected]="profileDetails.LifeCyclePhaseId" (callback)="ChangeLifeCycle()" [selectText]="'Select'"
                           [ariaLabelledby]="'LifecyclePhase'">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="(endToEndType == 'C' || endToEndType == 'O')">
        <div class="c-custom-select__wrapper">
          <label for="bcmPriority">BCM Priority<span *ngIf="DeliveryPortfolioDetails.RbeId == 2" class="required">&nbsp;*</span><a href="javascript:void(0)" aria-label="Click here to see the BCM Priority definitions" data-myw-title="Click here to see the BCM Priority definitions" data-myw-toggle="tooltip" data-myw-placement="bottom" data-target="#bcmPriorityDescriptioninfo" data-toggle="modal" class="c-myWizard-select-opt" (click)="showBCMPriority()">What is it?</a></label>
          <search-dropdown id="bcmPriority"
                           name="bcmPriority" #bcmPriority="ngModel"
                           [options]="profileDetails.BcmPriority"
                           [formname]="profileform" [disableDropdown]="RoleAccess=='V'"
                           [errormessage]="'Please select BCM Priority'"
                           [required]="DeliveryPortfolioDetails.RbeId == 2" [isShow]="true" [labelInfo]="'BCMPriority'"
                           [selected]="profileDetails.BcmPriorityId" [selectText]="'Select'"
                           [(ngModel)]="profileDetails.BcmPriorityId">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input">
          <label for="customer">Customer</label>
          <p class="o-input__input -customScroll -textAreaInput" [innerHTML]="profileDetails.Customer"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area">
        <div class="c-custom-select__wrapper -required" *ngIf="CreatePage">
          <label for="deployRegion">Deploy Region</label>
          <search-dropdown id="deployRegionName" name="deployRegionName" #deployRegionNamee="ngModel" [options]="profileDetails.DeployRegion"
                           [formname]="profileform" [required]="true" [isShow]="true"
                           [errormessage]="'Please select Deploy Region'"
                           [disableDropdown]="RoleAccess=='V' || profileDetails.DeployRegionName != null"
                           [selected]="profileDetails.DeployRegionName" (callback)="ChangeDeployRegion()" [selectText]="'Select'"
                           [(ngModel)]="profileDetails.DeployRegionName">
          </search-dropdown>
        </div>
        <div class="c-custom-select__wrapper  -required" *ngIf="!CreatePage">
          <label for="deployRegion">Deploy Region</label>
          <p class="o-input__input -disabled" [innerHTML]="selectedDeployRegionName"></p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'O'">
        <div class="o-input">
          <label for="contractId">Contract Id</label>
          <input type="text"
                 name="transitioncontractid"
                 #opportunityid="ngModel"
                 autocomplete="off"
                 aria-labelledby="TransitionContractID"
                 value="TransitionContractID"
                 maxlength="10"
                 (paste)="OnPasteNumericTransition($event)" (keypress)="KeyPressNumericTransition($event)"
                 (input)="OnContractIDChange(profileDetails.TransitionContractId)"
                 [disabled]="(profileDetails.IsCMWOpportunity && profileDetails.IsCMWOppContractId) || !IsAdmin || RoleAccess=='V'"
                 [(ngModel)]="profileDetails.TransitionContractId"
                 class="o-input__input" />
          <div *ngIf="profileDetails.IsContractIdValidated" aria-live="polite" aria-atomic="true" role="status" class="o-input__error-text is-error"><span>{{profileDetails.ContractTransitionStatus}}</span></div>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C' && DeliveryPortfolioDetails.CompanyId == 1">
        <div class="o-input">
          <label for="country">Country/Location</label>
          <p class="o-input__input -customScroll -textAreaInput" [innerHTML]="DeliveryPortfolioDetails.AccentureGeographicCountryName"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C'">
        <div class="o-input" *ngIf="DeliveryPortfolioDetails.CompanyId==2">
          <label for="avanadeChannel">Avanade Channel</label>
          <input type="text" aria-labelledby="AvanadeChannel" class="o-input__input -disabled" value="Direct"  />
          <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
        </div>
        <div class="c-custom-select__wrapper" *ngIf="DeliveryPortfolioDetails.CompanyId!=2">
          <label for="avanadeChannel">Avanade Channel</label>
          <search-dropdown id="avanadeChannel" name="avanadeChannel" avanadeChannel="ngModel"
                           [labelInfo]="'AvanadeChannel'" [options]="DeliveryPortfolioDetails.AvanadeChannel"
                           [disableDropdown]="RoleAccess=='V'" [isShow]="true" [formname]="profileform"
                           [selected]="DeliveryPortfolioDetails.AvanadeChannelId" (callback)="avanadeChannelChange()"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeChannelId">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C' && DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="o-input">
          <label for="avanadeGeographicArea">Avanade Geographic Area</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.AvanadeGeographicAreaName"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C' && DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="c-custom-select__wrapper -required">
          <label for="avanadeGeographicUnit">Avanade Geographic Unit</label>
          <search-dropdown id="avanadeGeographicUnit" name="avanadeGeographicUnit" #avanadeGeographicUnit="ngModel"
                           [labelInfo]="'AvanadeGeographicUnit'" [options]="DeliveryPortfolioDetails.AvandeGeographicUnits" [isShow]="true"
                           [formname]="profileform"
                           [errormessage]="'Please select Avanade Geographic Unit'"
                           [disableDropdown]="RoleAccess=='V' || DeliveryPortfolioDetails.AvanadeChannelId != 2" [required]="true" [selectText]="'Select'"
                           [selected]="DeliveryPortfolioDetails.AvanadeGeographicUnitId" (callback)="avanadeGeoUnitChange(DeliveryPortfolioDetails.AvanadeGeographicUnitId,true)"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeGeographicUnitId">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C' && DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="c-custom-select__wrapper -required">
          <label for="avanadeCountry">Avanade Country/Location</label>
          <search-dropdown id="avanadeCountry" name="avanadeCountry" #avanadeCountry="ngModel" [labelInfo]="'AvanadeCountry'" [options]="AvanadeGeoCountryLoad" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V' || DeliveryPortfolioDetails.AvanadeChannelId != 2" [required]="true" [selectText]="'Select'"
                           [errormessage]="'Please select Avanade Country/Location'"
                           [selected]="DeliveryPortfolioDetails.AvanadeCountryId"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeCountryId">
          </search-dropdown>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'C' && (DeliveryPortfolioDetails.AvanadeChannelId==1 || DeliveryPortfolioDetails.AvanadeChannelId==2)">
        <div class="c-custom-select__wrapper">
          <label for="avanadeGSA">Avanade GSA</label>
          <search-dropdown id="avanadeGSA" name="avanadeGSA" #avanadeGSA="ngModel" [labelInfo]="'AvanadeGSA'"
                           [options]="DeliveryPortfolioDetails.AvanadeGSA" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V'" [required]="ValidationMarketUnit && DeliveryPortfolioDetails.AvanadeChannelId!=0 && DeliveryPortfolioDetails.AvanadeGSAId==null && DeliveryPortfolioDetails.CompanyId == 2"
                           [selected]="DeliveryPortfolioDetails.AvanadeGSAId" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeGSAId">
          </search-dropdown>
        </div>
      </div>
    </div>
    <div class="c-myWizard-form__input" *ngIf="!CreatePage && endToEndType != 'C'">
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C'">
        <div [ngClass]="endToEndType != 'C' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'" *ngIf="endToEndType != 'C' && !profileDetails.IsCMWOpportunity">
          <label for="company">Company</label>
          <search-dropdown id="company" name="company" #company="ngModel" [options]="DeliveryPortfolioDetails.Companies" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V' || RoleTypeCode == false || IsCompanyDisable" [required]="true" [labelInfo]="'Company'"
                           [errormessage]="'Please select Company'"
                           [selected]="DeliveryPortfolioDetails.CompanyId" (callback)="CompanyChange(DeliveryPortfolioDetails.CompanyId)" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.CompanyId">
          </search-dropdown>
        </div>
        <div class="c-custom-select__wrapper" *ngIf="!CreatePage && profileDetails.IsCMWOpportunity">
          <label for="company">Company</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.CompanyName"></p>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C'">
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'" *ngIf="endToEndType == 'O' && !profileDetails.IsCMWOpportunity">
          <label for="managementMarket">Market</label>
          <search-dropdown id="managementMarket" name="managementMarket" #managementMarket="ngModel" [options]="DeliveryPortfolioDetails.ManagementMarkets" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V'" [required]="true" [labelInfo]="'OperatingGroup'"
                           [errormessage]="'Please select Market'"
                           [selected]="DeliveryPortfolioDetails.ManagementMarketId" (callback)="ManagementMarkeChange(DeliveryPortfolioDetails.ManagementMarketId)" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.ManagementMarketId">
          </search-dropdown>
        </div>
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'" *ngIf="!CreatePage && profileDetails.IsCMWOpportunity">
          <label for="managementMarket">Market</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.ManagementMarket"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
        <div class="c-custom-select__wrapper" *ngIf="endToEndType == 'I'">
          <label for="managementMarket">Market</label>
          <search-dropdown id="managementMarket" name="managementMarket" #managementMarket="ngModel" [options]="DeliveryPortfolioDetails.ManagementMarkets" [isShow]="true"
                           [disableDropdown]="RoleAccess=='V'" [labelInfo]="'OperatingGroup'" [formname]="profileform"
                           [selected]="DeliveryPortfolioDetails.ManagementMarketId" (callback)="ManagementMarkeChange(DeliveryPortfolioDetails.ManagementMarketId)" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.ManagementMarketId">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C'">
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'" *ngIf="endToEndType == 'O' && !profileDetails.IsCMWOpportunity">
          <label for="managementMarketUnit">Market Unit</label>
          <search-dropdown id="managementMarketUnit" name="managementMarketUnit" #managementMarket="ngModel" [options]="DeliveryPortfolioDetails.MMU" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V' || DeliveryPortfolioDetails.ManagementMarketId == null" [required]="DeliveryPortfolioDetails.ManagementMarketId != null" [filterId]="DeliveryPortfolioDetails.ManagementMarketId" [labelInfo]="'Select marketunit'"
                           [errormessage]="'Please select Market Unit'"
                           [selected]="DeliveryPortfolioDetails.ManagementMarketUnitId" (callback)="MarketUnitChange(DeliveryPortfolioDetails.ManagementMarketUnitId)" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.ManagementMarketUnitId">
          </search-dropdown>
        </div>
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'" *ngIf="!CreatePage && profileDetails.IsCMWOpportunity">
          <label for="managementMarketUnit">Market Unit</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.ManagementMarketUnit"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
        <div class="c-custom-select__wrapper" *ngIf="endToEndType == 'I'">
          <label for="managementMarketUnit">Market Unit</label>
          <search-dropdown id="managementMarketUnit" name="managementMarketUnit" #managementMarketUnit="ngModel" [options]="DeliveryPortfolioDetails.MMU" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V' || DeliveryPortfolioDetails.ManagementMarketId == null" [filterId]="DeliveryPortfolioDetails.ManagementMarketId" [labelInfo]="'Select marketunit'"
                           [selected]="DeliveryPortfolioDetails.ManagementMarketUnitId" (callback)="MarketUnitChange(DeliveryPortfolioDetails.ManagementMarketUnitId)" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.ManagementMarketUnitId">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C'">
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'" *ngIf="endToEndType == 'O' && !profileDetails.IsCMWOpportunity">
          <label for="managementClientGroup">Primary Client Group</label>
          <search-dropdown id="managementClientGroup" name="managementClientGroup" #managementClientGroup="ngModel" [options]="DeliveryPortfolioDetails.MCG" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V'" [required]="true" [labelInfo]="'ClientServiceGroup'"
                           [selected]="DeliveryPortfolioDetails.ManagementClientGroupId" (callback)="ClientGroupChange()" [selectText]="'Select'"
                           [errormessage]="'Please select Primary Client Group'"
                           [(ngModel)]="DeliveryPortfolioDetails.ManagementClientGroupId">
          </search-dropdown>
        </div>
        <div [ngClass]="endToEndType == 'O' ? 'c-custom-select__wrapper -required' : 'c-custom-select__wrapper'" *ngIf="!CreatePage && profileDetails.IsCMWOpportunity">
          <label for="managementClientGroup">Primary Client Group</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.ManagementClientGroupName"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
        <div class="c-custom-select__wrapper" *ngIf="endToEndType == 'I'">
          <label for="managementClientGroup">Primary Client Group</label>
          <search-dropdown id="managementClientGroup" name="managementClientGroup" #managementClientGroup="ngModel" [options]="DeliveryPortfolioDetails.MCG" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V'" [labelInfo]="'ClientServiceGroup'"
                           [selected]="DeliveryPortfolioDetails.ManagementClientGroupId" (callback)="ClientGroupChange()" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.ManagementClientGroupId">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I'">
        <div class="c-custom-select__wrapper">
          <label>Industry Segment</label>
          <multi-selectdropdown name="industrysegment" #industrysegment="ngModel" id="IndustrySegmentInfo" [options]="DeliveryPortfolioDetails.IndustrySegments"
                                [selected]="DeliveryPortfolioDetails.IndustryInputs" [disableDropdown]="RoleAccess=='V'" [formname]="profileform" [name]="'IndustrySegement'"
                                [(ngModel)]="DeliveryPortfolioDetails.IndustryInputs" [ariaLabelledby]="'IndustrySegement'" [errormessage]="'Please select Industry Segment'"></multi-selectdropdown>

        </div>
      </div>


      <!--<div class="form-group" *ngIf="endToEndType == 'I'">
        <div class="col-xs-12 col-sm-5 col-lg-4">
          <label class="control-label" id="IndustrySegmentInfo"><strong>Industry Segment</strong></label>
          <br /><a name="manageindustrysegment" class="link link-font-size" *ngIf="RoleAccess != 'V' && endToEndType == 'I'" (click)="showIndustrySegment()" href="javascript:void(0)" aria-label="Click here to add/change Industry Segment(opens a new window)">Click here to add/change Industry Segment</a>
        </div>
        <div class="col-xs-12 col-sm-7 col-lg-8">
          <textarea name="industrysegment" #industrysegment="ngModel" aria-labelledby="IndustrySegment" [ngClass]="{'has-error': industrysegment?.errors?.required}" readonly title="{{ RoleAccess=='V' ? DeliveryPortfolioDetails.IndustrySegement: DeliveryPortfolioDetails.IndustrySegement +'&#013;Click on link to add/change industry segment'}}" [required]="true" [(ngModel)]="DeliveryPortfolioDetails.IndustrySegement" class="form-control text-box-view" rows="2" [disabled]="true"></textarea>
          <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage" [ngClass]="{'has-error': industrysegment?.errors?.required}">Please select Industry Segment</div>
        </div>
      </div>-->
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I'">
        <div class="c-custom-select__wrapper">
          <label for="subgroupCategory">Services/Factories Provider Category</label>
          <search-dropdown id="subgroupCateogry" name="subgroupCateogry" subgroupCateogry="ngModel" [options]="DeliveryPortfolioDetails.SubgroupCategory" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V' || DisableSubgroupDropdown" (callback)="SubgroupCategoryChange()" [labelInfo]="'SubgroupCategory'"
                           [selected]="DeliveryPortfolioDetails.SelectedSubgroupCategory" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.SelectedSubgroupCategory">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType == 'I'">
        <div class="c-custom-select__wrapper " [ngClass]="{'-required':DeliveryPortfolioDetails.SelectedSubgroupCategory != null}">
          <label for="deliverySubgroup">Services/Factories Provider</label>
          <search-dropdown id="deliverySubgroup" name="deliverySubgroup" #deliverySubgroup="ngModel" [options]="DeliveryPortfolioDetails.DeliverySubgroup" [isShow]="true" [required]="DeliveryPortfolioDetails.SelectedSubgroupCategory != null"
                           [formname]="profileform" (callback)="SubGroupChange()" [labelInfo]="'Subgroup'"
                           [disableDropdown]="RoleAccess=='V' || DisableSubgroupDropdown" [filterId]="DeliveryPortfolioDetails.SelectedSubgroupCategory"
                           [selected]="DeliveryPortfolioDetails.SelectedDeliverySubgroup" [selectText]="'Select'"
                           [errormessage]="'Please select Services/Factories Provider'"
                           [(ngModel)]="DeliveryPortfolioDetails.SelectedDeliverySubgroup">
          </search-dropdown>
        </div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C' && DeliveryPortfolioDetails.CompanyId == 1">
        <div class="c-custom-select__wrapper -required" *ngIf="!profileDetails.IsCMWOpportunity">
          <label for="countries">Country/Location</label>
          <search-dropdown id="countries" name="countries" #countries="ngModel" [options]="DeliveryPortfolioDetails.Countries" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V'" [required]="true" [labelInfo]="'Country'"
                           [selected]="DeliveryPortfolioDetails.CountryId" [selectText]="'Select'"
                           [errormessage]="'Please select Country/Location'"
                           [(ngModel)]="DeliveryPortfolioDetails.CountryId">
          </search-dropdown>
        </div>
        <div class="c-custom-select__wrapper" *ngIf="!CreatePage && profileDetails.IsCMWOpportunity">
          <label for="country">Country/Location</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.AccentureGeographicCountryName"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C'">
        <div class="c-custom-select__wrapper" *ngIf="DeliveryPortfolioDetails.CompanyId!=2">
          <label for="avanadeChannel">Avanade Channel</label>
          <search-dropdown id="avanadeChannel" name="avanadeChannel" #avanadeChannel="ngModel" [labelInfo]="'AvanadeChannel'" [options]="DeliveryPortfolioDetails.AvanadeChannel"
                           [disableDropdown]="RoleAccess=='V'" [isShow]="true"
                           [selected]="DeliveryPortfolioDetails.AvanadeChannelId" (callback)="avanadeChannelChange()" [formname]="profileform"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeChannelId">
          </search-dropdown>
        </div>
        <div class="c-custom-select__wrapper" *ngIf="DeliveryPortfolioDetails.CompanyId==2">
          <label for="avanadeChannel">Avanade Channel</label>
          <input type="text" aria-labelledby="AvanadeChannel" class="o-input__input -disabled" value="Direct" />
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C' && DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="o-input">
          <label for="avanadeGeographicArea">Avanade Geographic Area</label>
          <p class="o-input__input -disabled" [innerHTML]="DeliveryPortfolioDetails.AvanadeGeographicAreaName"></p>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C' && DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="c-custom-select__wrapper -required">
          <label for="avanadeGeographicUnit">Avanade Geographic Unit</label>
          <search-dropdown id="avanadeGeographicsUnit" name="avanadeGeographicsUnit" #avanadeGeographicsUnit="ngModel" [labelInfo]="'AvanadeGeographicUnit'" [options]="DeliveryPortfolioDetails.AvandeGeographicUnits" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V' || (profileDetails.IsCMWOpportunity && DeliveryPortfolioDetails.AvanadeChannelId == 1)" [required]="true" [selectText]="'Select'"
                           [selected]="DeliveryPortfolioDetails.AvanadeGeographicUnitId" (callback)="avanadeGeoUnitChange(DeliveryPortfolioDetails.AvanadeGeographicUnitId,true)"
                           [errormessage]="'Please select Avanade Geographic Unit'"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeGeographicUnitId">
          </search-dropdown>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C' && DeliveryPortfolioDetails.AvanadeChannelId != 0">
        <div class="c-custom-select__wrapper -required">
          <label for="avanadeCountryLocation">Avanade Country/Location</label>
          <search-dropdown id="avanadeCountryId" name="avanadeCountryId" #avanadeCountryId="ngModel" [labelInfo]="'AvanadeCountry'" [options]="AvanadeGeoCountryLoad" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V' || (profileDetails.IsCMWOpportunity && DeliveryPortfolioDetails.AvanadeChannelId == 1)" [required]="true" [selectText]="'Select'"
                           [selected]="DeliveryPortfolioDetails.AvanadeCountryId"
                           [errormessage]="'Please select Avanade Country/Location'"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeCountryId">
          </search-dropdown>
        </div>
        <div aria-live="polite" aria-atomic="true" role="status" class="form-errormessage">This field is required.</div>
      </div>
      <div class="c-myWizard-form__input-area" *ngIf="endToEndType != 'C' && (DeliveryPortfolioDetails.AvanadeChannelId==1 || DeliveryPortfolioDetails.AvanadeChannelId==2)">
        <div class="c-custom-select__wrapper">
          <label for="avanadeGSA">Avanade GSA</label>
          <search-dropdown id="avanadeGSA" name="avanadeGSA" #avanadeGSA="ngModel" [labelInfo]="'AvanadeGSA'" [options]="DeliveryPortfolioDetails.AvanadeGSA" [isShow]="true" [formname]="profileform"
                           [disableDropdown]="RoleAccess=='V'" [required]="ValidationMarketUnit && DeliveryPortfolioDetails.AvanadeChannelId!=0 && DeliveryPortfolioDetails.AvanadeGSAId==null && DeliveryPortfolioDetails.CompanyId == 2"
                           [selected]="DeliveryPortfolioDetails.AvanadeGSAId" [selectText]="'Select'"
                           [(ngModel)]="DeliveryPortfolioDetails.AvanadeGSAId">
          </search-dropdown>
        </div>
      </div>
    </div>

  </div>
  <div class="c-myWizard-update__container" *ngIf="!CreatePage">
    <div class="c-myWizard-container">
      <div class="c-myWizard-update__row">
        <div class="c-myWizard-update__details">
          <div class="c-myWizard-update__input">
            <label>Last Updated On:</label>
            <span class="-input">{{profileDetails.LastModifiedOn |date:'dd MMM yyyy h:mm a'}}{{profileDetails.LastModifiedOn == null ? "" : " (GMT)"}}</span>
          </div>
          <div class="c-myWizard-update__input">
            <label>Last Updated By:</label>
            <span class="-input">{{profileDetails.LastModifiedBy}}</span>
          </div>
        </div>
        <div class="c-myWizard-update__contract">
          <div class="c-myWizard-update__input" *ngIf="(endToEndType == 'C' && profileDetails.IsCMWContract) || (endToEndType == 'O' && profileDetails.IsCMWOpportunity) || (endToEndType == 'O' && profileDetails.CreatedByApp == 'AOIO')">
            <label>Auto-sourced {{(endToEndType== 'C' && profileDetails.IsCMWContract) ? "Contract" : (((endToEndType == 'O' && profileDetails.IsCMWOpportunity) || (endToEndType == 'O' && profileDetails.CreatedByApp == 'AOIO')) ? "Opportunity" : "")}} Details Last  From: </label>
            <span class="-input" *ngIf="(endToEndType == 'C' && profileDetails.IsCMWContract) || (endToEndType == 'O' && profileDetails.IsCMWOpportunity) || (endToEndType == 'O' && profileDetails.CreatedByApp == 'AOIO')">{{(endToEndType == 'C' && profileDetails.IsCMWContract) || (endToEndType == 'O' && profileDetails.IsCMWOpportunity) ? "CMW" : "NEST"}}</span>
          </div>
          <div class="c-myWizard-update__input" *ngIf="(endToEndType == 'C' && profileDetails.IsCMWContract) || (endToEndType == 'O' && profileDetails.IsCMWOpportunity) || (endToEndType == 'O' && profileDetails.CreatedByApp == 'AOIO')">
            <label>Auto-sourced {{(endToEndType== 'C' && profileDetails.IsCMWContract) ? "Contract" : (((endToEndType == 'O' && profileDetails.IsCMWOpportunity) || (endToEndType == 'O' && profileDetails.CreatedByApp == 'AOIO')) ? "Opportunity" : "")}} Details Last  On: </label>
            <span class="-input" *ngIf="(endToEndType == 'C' && profileDetails.IsCMWContract) || (endToEndType == 'O' && profileDetails.IsCMWOpportunity) || (endToEndType == 'O' && profileDetails.CreatedByApp == 'AOIO')">{{(endToEndType== 'C' && profileDetails.IsCMWContract) ? (profileDetails.AutosourcedLastModifiedOn |date:'dd MMM yyyy h:mm a') : (((endToEndType == 'O' && profileDetails.IsCMWOpportunity) || (endToEndType == 'O' && profileDetails.CreatedByApp == 'AOIO')) ? (profileDetails.AutosourcedOpportunityLastModifiedOn |date:'dd MMM yyyy h:mm a') : "")}} (GMT)</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="c-select-card">
    <div class="container">
      <div class="c-select-card__wrapper">
        <div class="c-select-card__left">
          <div class="c-select-card__text">Changes made will be lost unless confirmed. Please click 'Save' button to confirm.</div>
        </div>
        <div class="c-select-card__right">
          <button href="javascript:void(0)" class="o-secondary-button" role="button" type="button" [ngClass]="{'-disabled':RoleAccess=='V'}"  (click)="cancel(profileform)" aria-label="Reset">Reset</button>
          <button href="javascript:void(0)" class="o-primary-button" form="profileForm" role="button" type="submit" value="submit" [ngClass]="{'-disabled':RoleAccess=='V'}" [disabled]="RoleAccess=='V' || DisableSave" aria-label="Save">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="c-select-card__placeholder"></div>
</form>

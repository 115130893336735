import { Component, HostListener, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TileService } from '../../core/services/tile.service';
import { DemographicsService } from '../../core/services/demographics.service';
import * as Enumerable from 'linq';
import { SharedService } from '../../core/services/shared.service';
import { SessionStorage } from '../../core/context/sessionstorage';
import cloneDeep from 'lodash/cloneDeep';
import { enumModuleAction, enumTile, enumFeatureAccess, enumAlternativeContacts, enumProfileTab } from '../../core/context/enum';
import { InitiateProfileComponent } from './initiate.profile.component';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
var $ = (<any>window).$;
import { InitiateProfileSharedService } from './initiate.profile.shared.service';
import { ScopeSelectorComponent } from '../scope.selector.component/scope.selector.component';
import { UnsavedChangesService } from '../../core/services/unsaved.changes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlternateContactComponent } from './model.popup.component/alternate.contact.component';
import { ReportingPortfolioAddEditComponent } from './model.popup.component/reporting.portfolio.add.edit.component';
import { ServiceGroupNetworkEditComponent } from './model.popup.component/service.group.network.edit.component';
import { HeaderComponent } from '../header.component/header.component';
import { BroadCastService } from '../../core/services/broadcast.service';
import { environment } from '../../../environments/environment';
var $ = (<any>window).$;
declare var window: any;

@Component({
  templateUrl: './keycontacts.component.html',
  providers: [TileService, InitiateProfileSharedService, ScopeSelectorComponent, BsModalService, DemographicsService, ReportingPortfolioAddEditComponent, HeaderComponent]
})

export class KeyContactsComponent {

  @ViewChild('contactsform') contactsForm: NgForm;
  unsavedSubscription: Subscription;
  unsavedConfirm: boolean = false;
  isContactsFormDirty: boolean = true; // Boolean added as an alternative to check form changes since autocomplete was setting form values as dirty on cancel and save
  DCforContract: any;
  DCforOppInit: any;
  AccountGroups: any;
  DeliveryGroups: any;
  DeliveryUnits: any;
  DeliveryLead: any;
  endToEndId = this.sessionStorage.endToEndId;
  endToEndType = this.sessionStorage.endToEndType;
  CreatePage: boolean;
  E2eDemoGraphics: any;
  KeyContactsDetails: any;
  TempRoleOptions: any;
  CDLEnableDisable: any;
  LoadDirectiveKeyContacts: boolean;
  TempListAlternateContact: any;
  IsTableMandatory: boolean = false;
  ExistingDominantId: any
  CurrentDominantId: any
  tempEndToEndDeliveryCenterContactsForOppInit: any
  tempEndToEndDeliveryCenterContacts: any;
  isInvalidEnterPriseId: boolean = false;
  DeliveryPortfolioDetails: any;
  IsValidEndToEndOverallDeliveryLead: any = false;
  RoleAccess = this.sessionStorage.roleAccessCode;
  SubmissionType: number;
  IsAdmin = this.sharedService.isAdmin(this.sessionStorage.roleTypeCode);
  path = this.location.path();
  IsOppInitNotCreated = false;
  DCLeading: any;
  tempData: any;
  isReportingVisible: boolean = false;
  AccountGroupId: any;
  DeliveryGroupId: any;
  DeliveryUnitId: any
  modalRefAlternateContactPopup: BsModalRef;
  modalServiceGroupNetworkPopup: BsModalRef;
  modalRefMetroCityPopup: BsModalRef;
  keyContactTabHighlight: boolean
  ProfileTabHighlight: boolean;
  involvedReportingPortfolioCount = 0;
  @Output() InitiateProfileTabSetup = new EventEmitter();
  RoleTypeCode = (this.sessionStorage.roleTypeCode == null || this.sessionStorage.roleTypeCode == undefined) ? false : (this.sessionStorage.roleTypeCode.indexOf('A') == 0) ? true : false;
    startIndex: number;
    endIndex: any;
  pageSize: number = 10;
  curPage: number = 0;
  noOfPages: number = 0;
  slicedGridData: any;
  IsProfileCompleted: boolean;
  ProfileCompletionSubscription: Subscription;
  isOpportunityCreator: boolean;
  isInitiativeCreator: boolean;
  IsOpportunityInitiativeCreator: boolean;
  LedByPortfolio: string;
  ExistingLeadingAGDGDU : any;


  //@HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedConfirm && this.isContactsFormDirty && this.sessionStorage.endToEndUId) {
      if (this.contactsForm.form.dirty) { return confirm('Changes you made may not be saved.'); }
      else { return true; }
    } else {
      return true;
    }
  }

  constructor(
    private demographicService: DemographicsService,
    private sessionStorage: SessionStorage,
    private sharedService: SharedService,
    private location: Location,
    private initiateProfileComponent: InitiateProfileComponent,
    private scopeSelectorComponent: ScopeSelectorComponent,
    private initiateProfileSharedService: InitiateProfileSharedService,
    private modalService: BsModalService,
    private tileService: TileService,
    private cdref: ChangeDetectorRef,
    private unsavedChangesService: UnsavedChangesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerComponent: HeaderComponent,
    private broadCastService: BroadCastService
  ) {
    this.unsavedSubscription = this.unsavedChangesService.currentMessage.subscribe((data) => {
      if (data == "keycontacts") {
        if (this.contactsForm.form.dirty && this.isContactsFormDirty) {
          if (confirm("Changes you made may not be saved.")) {
            this.unsavedConfirm = true;
            this.sessionStorage.isUserAlert = false;
          } else {
            this.sessionStorage.isUserAlert = true;
          }
        }
        else {
          this.sessionStorage.isUserAlert = false;
        }
      }
    });

    this.ProfileCompletionSubscription = this.broadCastService.refreshProfileCompletion().subscribe((response) => {
      if (response != null) {
        this.profileCompletedStatus(response);
      }
    });
  }

  

  ngOnInit() {
    this.initiateProfileSharedService.SetupProfileAndAdminPage(this);
    if (!this.sessionStorage.endToEndUId && !this.IsOpportunityInitiativeCreator) {
      this.router.navigate(['MyWizard/Back/Profile'], { queryParams: { endToEndUId: this.activatedRoute.snapshot.params.redirectId } });
      return false;
    }
    this.endToEndId = this.path.indexOf('opportunity') > -1 || this.path.indexOf('internalinitiative') > -1 ? this.sessionStorage.createdE2EId : this.sessionStorage.endToEndId;
    this.LoadKeyContactsTab(false);
    this.IsProfileCompleted = this.sessionStorage.IsProfileCompleted;
    setTimeout(() => {
      window.TooltipFunc();
    }, 0);
  }

  ngOnDestroy() {
    this.unsavedSubscription.unsubscribe();
    this.ProfileCompletionSubscription.unsubscribe();
  }

  OnBackClick() {
    this.initiateProfileComponent.OnBackClick();
  }

  ngAfterViewInit() {
    this.IsProfileCompleted = this.sessionStorage.IsProfileCompleted;
  }
  profileCompletedStatus(status) {
    this.IsProfileCompleted = status;
  }

  showReportingPortfolio(deliveryCenter: any, index: any) {
    //this.isReportingVisible = true;
    index = this.curPage * this.pageSize + index;
    if (deliveryCenter == null) {
      var initialStateEmpty = {
        KeyContactsDetails: this.KeyContactsDetails,
        DCforOppInit: this.DCforOppInit,

        DCforContract: this.DCforContract,
        AccountGroups: this.AccountGroups,
        DeliveryGroups: this.DeliveryGroups,
        DeliveryUnits: this.DeliveryUnits,

        AccountGroupId: this.AccountGroupId,
        DeliveryGroupId: this.DeliveryGroupId,
        DeliveryUnitId: this.DeliveryUnitId,
        index: index,
        E2eDemoGraphics: this.E2eDemoGraphics,
        E2EType: this.E2eDemoGraphics.E2EType,
        action: "Add",
        scrollYPosition: window.scrollY
      };
    }
    else {
      var initialStateExist = {
        KeyContactsDetails: this.KeyContactsDetails,
        DCforOppInit: this.DCforOppInit,
        deliveryCenter: deliveryCenter,

        DCforContract: this.DCforContract,
        AccountGroups: this.KeyContactsDetails.AccountGroups,
        DeliveryGroups: this.KeyContactsDetails.DeliveryGroups,
        DeliveryUnits: this.KeyContactsDetails.DeliveryUnits,

        AccountGroupId: deliveryCenter.AccountGroupId,
        DeliveryCenterId: deliveryCenter.DeliveryCenterId,
        DeliveryGroupId: deliveryCenter.DeliveryGroupId,
        DeliveryUnitId: deliveryCenter.DeliveryUnitId,
        DeliveryLead: deliveryCenter.DeliveryLead,
        index: index,
        E2eDemoGraphics: this.E2eDemoGraphics,
        E2EType: this.E2eDemoGraphics.E2EType,
        action: "Edit",
        scrollYPosition: window.scrollY
      }
    }
    const initialState = deliveryCenter == null ? initialStateEmpty : initialStateExist;
    this.modalRefMetroCityPopup = this.modalService.show(ReportingPortfolioAddEditComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
    this.modalRefMetroCityPopup.content.reportingAddEdit.subscribe(result => {
      this.KeyContactsDetails = result.KeyContactsDetails;
      this.contactsForm.form.markAsDirty();
      this.paginationClick(0);
    });
  }

  LoadKeyContactsTab(setfocus) {
    if (!this.CreatePage) {
      this.RoleAccess = this.sessionStorage.roleAccessCode == 'V' ? this.sessionStorage.roleAccessCode : (this.sessionStorage.restrictedInstanceIndicator == 'Y' && !environment.restrictedInstance ? 'V' : this.sessionStorage.roleAccessCode);
    }
    this.AccountGroupId = "";
    this.DeliveryGroupId = "";
    this.DeliveryUnitId = "";
    $("#deliveryLead").val('');
    $("#deliveryLead").removeClass("input-blank-error");
    this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
    // Fix for - While loading, table list was getting empty
    // Reverting as Autocomplete controls are not working - Autocomplete is based on reloading DOM

    //if (this.endtoendType == "C") {
    //  this.KeyContactsDetails.EndToEndDeliveryCenterContacts = [];
    //  this.KeyContactsDetails.EndToEndServiceGroupContacts = [];
    //} else {
    //  this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit = [];
    //}

    this.demographicService.getKeyContactsTabDetails(this.endToEndId, this.endToEndType, "KeyContact", this.RoleTypeCode, this.CreatePage).subscribe((response: any) => {
      this.E2eDemoGraphics = response;
      this.KeyContactsDetails = response.KeyContacts;
      this.ResetAddDominentRow();
      this.AccountGroups = [];
      this.DeliveryGroups = [];
      this.DeliveryUnits = [];

      setTimeout(() => {
        window.dropdownComponent();
        window.tabsComponent();
      }, 0);
      
      this.KeyContactsDetails.EndToEndServiceGroupContacts.forEach((value: any) => {
        value.DominantServiceGroupId = value.DominantServiceGroupId != '' ? parseInt(value.DominantServiceGroupId) : 0;

      });
      this.TempRoleOptions = cloneDeep(this.KeyContactsDetails.AlternateContactsList);
      this.CDLEnableDisable = this.KeyContactsDetails.ContractDeliveryLead != null ? true : false;
      this.LoadDirectiveKeyContacts = true;
      this.TempListAlternateContact = cloneDeep(this.KeyContactsDetails.EndToEndAlternateContacts);
      this.DCforContract = cloneDeep(this.KeyContactsDetails.DC);
      this.DCforOppInit = cloneDeep(this.KeyContactsDetails.DC);
      $('#hiddenContractDeliveryLead').val(this.KeyContactsDetails.ContractDeliveryLead);
      $('#hiddenDeliveryLead').val("");
      if (setfocus) {
        $("[name='contactsform']").find('input[type!=hidden]:first').focus();
      }
      if (this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit == null && this.KeyContactsDetails.EndToEndDeliveryCenterContacts == null) {
        this.IsTableMandatory = true;
      }
      else {
        if (this.endToEndType == "C") {
          var selecteddeliverycenter: any = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.DCLeadingdeliveryCenterId != -1).select((s: any) => new Object({ DcLead: s.LedByIndicator, deliveryCenterId: s.DeliveryCenterId, EndToEndDeliveryCenterId: s.EndToEndDeliveryCenterId, DcCountryName: s.DcCountryName, DcCountryId : s.DcCountryId  })).firstOrDefault();
          if (selecteddeliverycenter == undefined) {
            this.IsTableMandatory = true;
          }
          else {
            this.ExistingDominantId = selecteddeliverycenter.EndToEndDeliveryCenterId;
            this.CurrentDominantId = selecteddeliverycenter.EndToEndDeliveryCenterId;
            this.LedByPortfolio = (selecteddeliverycenter.DcCountryId != 998 && selecteddeliverycenter.DcCountryId != 997) ? selecteddeliverycenter.DcCountryName : null;
          }
        }
        else {
          var selecteddeliverycenter: any = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => x.DCLeadingdeliveryCenterId != -1).select((s: any) => new Object({ DcLead: s.LedByIndicator, deliveryCenterId: s.DeliveryCenterId, EndToEndDeliveryCenterId: s.EndToEndDeliveryCenterId, DcCountryName: s.DcCountryName, DcCountryId : s.DcCountryId  })).firstOrDefault();
          if (selecteddeliverycenter == undefined) {
            this.IsTableMandatory = true;
          }
          else {
            this.ExistingDominantId = selecteddeliverycenter.EndToEndDeliveryCenterId;
            this.CurrentDominantId = selecteddeliverycenter.EndToEndDeliveryCenterId;
            this.LedByPortfolio = (selecteddeliverycenter.DcCountryId != 998 && selecteddeliverycenter.DcCountryId != 997) ? selecteddeliverycenter.DcCountryName : null;
          }
        }
      }

      if(this.endToEndType == "C"){
         var selecteddeliverycenterLeadingAGDGDU: any = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.LeadingAGDGDUId != -1).select((s: any) => new Object({ DcLead: s.LedByIndicator, deliveryCenterId: s.DeliveryCenterId, EndToEndDeliveryCenterId: s.EndToEndDeliveryCenterId, DcCountryName: s.DcCountryName  })).firstOrDefault();
           this.ExistingLeadingAGDGDU = selecteddeliverycenterLeadingAGDGDU != null ? selecteddeliverycenterLeadingAGDGDU.EndToEndDeliveryCenterId : null;
      }
      else{
        var selecteddeliverycenterLeadingAGDGDU: any = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => x.LeadingAGDGDUId != -1).select((s: any) => new Object({ DcLead: s.LedByIndicator, deliveryCenterId: s.DeliveryCenterId, EndToEndDeliveryCenterId: s.EndToEndDeliveryCenterId, DcCountryName: s.DcCountryName })).firstOrDefault();
           this.ExistingLeadingAGDGDU = selecteddeliverycenterLeadingAGDGDU != null ? selecteddeliverycenterLeadingAGDGDU.EndToEndDeliveryCenterId : null;
      }

      this.tempEndToEndDeliveryCenterContactsForOppInit = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit);
      this.tempEndToEndDeliveryCenterContacts = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContacts);
      this.paginationClick(0);
      this.SetDominantEndToEndDC();
      this.IsOppInitNotCreated = false;
      this.initiateProfileSharedService.ValidateDemographicTabHighlight(this);
      if (this.CreatePage) {
        this.InitiateProfileTabSetup.emit({ IsOppInitNotCreated: this.IsOppInitNotCreated, keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.KeyContacts });
      }
      this.initiateProfileSharedService.errorTabHighlight.subscribe(result => {
        this.InitiateProfileTabSetup.emit({ IsOppInitNotCreated: this.IsOppInitNotCreated, keyContactTabHighlight: this.keyContactTabHighlight, ProfileTabHighlight: this.ProfileTabHighlight, tabId: enumProfileTab.KeyContacts });
      });
    });
    this.sharedService.UsageLogging(enumModuleAction.Tile, enumTile.InitiateProfile, enumFeatureAccess.KeyContacts);
  };

  async saveKeyContacts(formvalid, form) {
    if (formvalid && $('#keycontacts').find('.has-error').length < 1) {
      var validationDeliveryLead = [];
      var validationServiceGroupDeliveryLead = [];
      var validationServiceGroupDeliveryManager = [];
      $('.reportingPortfolioDeliveryLead').each((i: any, x: any) => {
        if (x.classList.contains('input-blank-error') && x.value != x.getAttribute('data') && x.value.length > 0) {
          validationDeliveryLead.push(false);
        } else {      
          validationDeliveryLead.push(true);
        }
      });

      $('.serviceGroupDeliveryLead').each((i: any, x: any) => {
        if (x.classList.contains('input-blank-error') && x.value != x.getAttribute('data') && x.value.length > 0) {
          validationDeliveryLead.push(false);
        } else {
          validationDeliveryLead.push(true);
        }
      });

      $('#servicegrouplead').find('tr td input[name^=deliverylead]').each(function () {
        if ($(this).hasClass('input-blank-error') && $(this).attr('deliveryLead') != $(this).val() && $(this).val().length > 0) {
          validationServiceGroupDeliveryLead.push(false);
          var item = this.KeyContactsDetails.EndToEndServiceGroupContacts.filter(x => x.DeliveryLead == $(this).val())[0];

        }
        else {
          validationServiceGroupDeliveryLead.push(true);
          $(this).removeClass('input-blank-error');
        }
      });

      var enterpriseId = this.KeyContactsDetails.ContractDeliveryLead;
      if (enterpriseId?.length > 0) {
        if ($('#hiddenContractDeliveryLead').val() != enterpriseId) {
          this.sharedService.openAlertPopup('Please enter valid Enterprise ID', window.scrollY);
          this.isInvalidEnterPriseId = true;
          $('#peoplePicker_contractDeliveryLead').css('border-color', '#ff0601');
          $('#peoplePicker_contractDeliveryLead').focus();
          return false;
        }
      }
      if(this.endToEndType == "C"){
           var autopopulateddeliverycenters = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => (x.DcCountryId != 998 && x.DcCountryId != 997) && (x.AccountGroup == null || x.DeliveryGroup == null || x.DeliveryUnit == null)).select((s: any) => s).count();
           if(autopopulateddeliverycenters > 0){
              this.sharedService.openAlertPopup('Account Group, Delivery Group and Delivery Unit are not updated for few Delivery Center(s). Please update it before proceeding. ', window.scrollY);
              return false;
           }
      }
      else{
        var autopopulateddeliverycenters = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => (x.DcCountryId != 998 && x.DcCountryId != 997) && (x.AccountGroup == null || x.DeliveryGroup == null || x.DeliveryUnit == null)).select((s: any) => s).count();
           if(autopopulateddeliverycenters > 0){
              this.sharedService.openAlertPopup('Account Group, Delivery Group and Delivery Unit are not updated for few Delivery Center(s). Please update it before proceeding. ', window.scrollY);
              return false;
           }
      }

      if (validationDeliveryLead.indexOf(false) != -1) {
        this.sharedService.openAlertPopup('Please Enter Valid Enterprise ID', window.scrollY);
        return false;
      }
      if (validationServiceGroupDeliveryLead.indexOf(false) != -1) {
        this.sharedService.openAlertPopup('Please Enter Valid Enterprise ID', window.scrollY);
        return false;
      }
      if (validationServiceGroupDeliveryManager.indexOf(false) != -1) {
        this.sharedService.openAlertPopup('Please Enter Valid Enterprise ID', window.scrollY);
        return false;
      }
      this.KeyContactsDetails.AlternateContactEIds = Enumerable.from(this.KeyContactsDetails.EndToEndAlternateContacts).select((x: any) => x.EnterpriseId).toArray().join(',');
      this.KeyContactsDetails.AlternateContactRoleIds = Enumerable.from(this.KeyContactsDetails.EndToEndAlternateContacts).select((x: any) => x.AlternateContactRoleTypeId).toArray().join(', ');
      this.KeyContactsDetails.OtherRoleDesc = Enumerable.from(this.KeyContactsDetails.EndToEndAlternateContacts).select((x: any) => x.OtherRole).toArray().join(', ');
      this.E2eDemoGraphics.EnterpriseId = this.sessionStorage.enterpriseId;


      if (this.endToEndType == "C") {
        var deliverycenters = this.KeyContactsDetails.EndToEndDeliveryCenterContacts;
        var duplicateDCCount = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.DuplicateDCExists == true).select((s: any) => s).count();

      }
      else {
        var deliverycenters = this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit;
      }
      if (this.endToEndType == "C" && duplicateDCCount > 0) {
        this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
        return false;
      }
      
      var dcDataOppInit = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => x.DcCountryName != 'OnShore' && x.DcCountryId != '997' && x.DcCountryId != '998' && (x.DeliveryCenterId == "" || x.AccountGroupId == "" || x.DeliveryGroupId == "" || x.DeliveryUnitId == "")).select((s: any) => s).count();
      if (dcDataOppInit > 0 && this.endToEndType != "C") {
          this.sharedService.openAlertPopup('Please fill mandatory fields', window.scrollY);
          return false;
      }

      var dcDataContract = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.DcCountryName != 'OnShore' && x.DcCountryId != '997' && x.DcCountryId != '998' && (x.DeliveryCenterId == "" || x.AccountGroupId == "" || x.DeliveryGroupId == "" || x.DeliveryUnitId == "")).select((s: any) => s).count();
      if (dcDataContract > 0 && this.endToEndType == "C") {
          this.sharedService.openAlertPopup('Please fill mandatory fields', window.scrollY);
          return false;
      }



      if (this.endToEndType == "C") {
        var contractOnshoreDeliveryLeadCSG = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.DcCountryId == 998).select((s: any) => s.DeliveryLead).firstOrDefault();
        this.KeyContactsDetails.OnshoreDeliveryLeadMCG = contractOnshoreDeliveryLeadCSG == undefined ? null : contractOnshoreDeliveryLeadCSG;
        var contractOnshoreDeliveryLeadGU = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.DcCountryId == 997).select((s: any) => s.DeliveryLead).firstOrDefault();
        this.KeyContactsDetails.OnshoreDeliveryLeadGU = contractOnshoreDeliveryLeadGU == undefined ? null : contractOnshoreDeliveryLeadGU;
      }
      else {
        var oppoInitOnshoreDeliveryLeadCSG = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => x.DcCountryId == 998).select((s: any) => s.DeliveryLead).firstOrDefault();
        this.KeyContactsDetails.OnshoreDeliveryLeadMCG = oppoInitOnshoreDeliveryLeadCSG == undefined ? null : oppoInitOnshoreDeliveryLeadCSG;
        var oppoInitOnshoreDeliveryLeadGU = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => x.DcCountryId == 997).select((s: any) => s.DeliveryLead).firstOrDefault();
        this.KeyContactsDetails.OnshoreDeliveryLeadGU = oppoInitOnshoreDeliveryLeadGU == undefined ? null : oppoInitOnshoreDeliveryLeadGU;
      }
      //Making Master data as null to avoid performance issue on save due to huge data passed in the payload
      this.KeyContactsDetails.AccountGroups = null;
      this.KeyContactsDetails.DeliveryGroups = null;
      this.KeyContactsDetails.DeliveryUnits = null;
      this.KeyContactsDetails.DC = null;
      this.KeyContactsDetails.AlternateContactsList = null;
      this.KeyContactsDetails.MCG = null;
      this.KeyContactsDetails.LocationHierarchy = null;
      this.E2eDemoGraphics.RoleTypeCode = this.RoleAccess;
      this.demographicService.postKeyContactsTabDetails(this.E2eDemoGraphics).subscribe((response: any) => {
        this.sharedService.openAlertPopup('Saved Successfully', window.scrollY);
        this.sessionStorage.isValidEndToEndOverallDeliveryLead = null;
        this.keyContactTabHighlight = false;
        if (form != undefined) {
          form.resetForm();
        //  this.RetainValueForm(form);
        }
        this.slicedGridData = [];
        this.LoadKeyContactsTab(false);
        this.initiateProfileComponent.LoadMandatoryDataAttrSet();
        //this.sharedService.ProcessDemographicAttribute(enumEntityType.EndToEnd, this.E2eDemoGraphics.E2EId, this.demographicService);
      });
    }
    else {
      this.sharedService.openAlertPopup('Please review the highlighted fields and update accordingly.', window.scrollY);
      await this.initiateProfileSharedService.focusErrorField('contactsform');
    }
    this.isContactsFormDirty = false;
  }
  onChange() {
    this.IsValidEndToEndOverallDeliveryLead = true;
    this.isInvalidEnterPriseId = false;
  }

  getDeliveryLeadLocation(deliveryLead, type) {
    this.demographicService.getDeliveryLeadLocation(deliveryLead, type).subscribe((response) => {
      this.KeyContactsDetails.DeliveryLeadLocation = response;
      this.sharedService.openAlertPopup('Delivery Lead Location(DTE) is updated. Please click on “Save” to update the “Led by” portfolio.', window.scrollY);
    });
  }
  DeiveryLeadChange(deliveryLead, $event) {                                                                                                                                                                                                                                   

  }



  showAlternativeContacts(item : any) {
    var pmoLead = Enumerable.from(this.KeyContactsDetails.EndToEndAlternateContacts).where((x: any) => x.AlternateContactRoleTypeId == enumAlternativeContacts.PMOLead).select((s: any) => new Object({ Id: s.AlternateContactRoleTypeId, Name: s.AlternateContactRoleName })).firstOrDefault();
    var roleRemoved = Enumerable.from(this.KeyContactsDetails.AlternateContactsList).where((x: any) => x.Id == enumAlternativeContacts.PMOLead).firstOrDefault();
    var transformationDirector = Enumerable.from(this.KeyContactsDetails.EndToEndAlternateContacts).where((x: any) => x.AlternateContactRoleTypeId == enumAlternativeContacts.TransformationDirector).select((s: any) => new Object({ Id: s.AlternateContactRoleTypeId, Name: s.AlternateContactRoleName })).firstOrDefault();
    var roleRemoved1 = Enumerable.from(this.KeyContactsDetails.AlternateContactsList).where((x: any) => x.Id == enumAlternativeContacts.TransformationDirector).firstOrDefault();
    if (pmoLead != undefined && roleRemoved != undefined) {
      for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
        if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.PMOLead) {
          this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
          break;
        }
      }
    }
    if (transformationDirector != undefined && roleRemoved1 != undefined) {
      for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
        if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.TransformationDirector) {
          this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
          break;
        }
      }
    }
    const initialState = { KeyContactsDetails: this.KeyContactsDetails, TempListAlternateContact: this.TempListAlternateContact, TempRoleOptions: this.TempRoleOptions, item: item };
    this.modalRefAlternateContactPopup = this.modalService.show(AlternateContactComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
   /* $(".modal-dialog").addClass('custom-modal');*/
    this.modalRefAlternateContactPopup.content.altnernateContactDetails.subscribe(result => {
     
      this.TempListAlternateContact = result.TempListAlternateContact;
    });

    this.modalRefAlternateContactPopup.content.alternateSaveDetails.subscribe(result => {
      if (result.saveData) {
        setTimeout(function () {
          window.TooltipFunc();
        }, 0);
        this.contactsForm.form.markAsDirty();
      }
    });
    if (this.endToEndType == "I")
    {
      for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
        if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.AutomationArchitect)
          this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
        if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.OPERALead)
          this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
      }
    }
    if (this.endToEndType == "I" || this.endToEndType == "O") {
      for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
        if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.TransformationDirector)
          this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
        if (this.KeyContactsDetails.AlternateContactsList[i] != undefined && this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.ClientExperienceLead)
          this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
          //if (this.KeyContactsDetails.AlternateContactsList[i] != undefined && this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.ContractManager)
        //  this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
      }
    }

  }

  showServiceGroupNetwork(item: any, i: any) {
    const initialState = { i: i, item: item, RoleAccess: this.RoleAccess, KeyContactsDetails : this.KeyContactsDetails }
    this.modalServiceGroupNetworkPopup = this.modalService.show(ServiceGroupNetworkEditComponent, {
      initialState,
      backdrop: "static",
      keyboard: false
    });
    this.modalServiceGroupNetworkPopup.content.ServiceGroupNetworkKeyContactsDetails.subscribe(result => {
      this.KeyContactsDetails = result.KeyContactsDetails;
      this.contactsForm.form.markAsDirty();
      
       setTimeout(function () {
        window.TooltipFunc();
        }, 0);
    })
  }


  RemoveContact(roleId, enterpriseid, roleName) {
    var deleted = enterpriseid.replace(/ /g, "");
    var endtoendType = this.sessionStorage.endToEndType;
    if (this.TempListAlternateContact != null && this.TempListAlternateContact != 'undefined') {
      for (var i = 0; i < this.TempListAlternateContact.length; i++) {
        var existing = this.TempListAlternateContact[i].EnterpriseId.replace(/ /g, "");
        if (this.TempListAlternateContact[i].AlternateContactRoleTypeId == roleId && existing == deleted) {
          this.TempListAlternateContact.splice(i, 1);
          break;
        }
      }
      if (roleId == enumAlternativeContacts.PMOLead || enumAlternativeContacts.TransformationDirector) {
        this.KeyContactsDetails.AlternateContactsList = cloneDeep(this.TempRoleOptions);
        for (var i = 0; i < this.TempListAlternateContact.length; i++) {
          if (this.TempListAlternateContact[i].AlternateContactRoleTypeId == enumAlternativeContacts.TransformationDirector) {
            for (var j = 0; j < this.KeyContactsDetails.AlternateContactsList.length; j++) {
              if (this.KeyContactsDetails.AlternateContactsList[j].Id == enumAlternativeContacts.TransformationDirector) {
                this.KeyContactsDetails.AlternateContactsList.splice(j, 1);
              }
            }
          }
        }
        for (var i = 0; i < this.TempListAlternateContact.length; i++) {
          if (this.TempListAlternateContact[i].AlternateContactRoleTypeId == enumAlternativeContacts.PMOLead) {
            for (var j = 0; j < this.KeyContactsDetails.AlternateContactsList.length; j++) {
              if (this.KeyContactsDetails.AlternateContactsList[j].Id == enumAlternativeContacts.PMOLead) {
                this.KeyContactsDetails.AlternateContactsList.splice(j, 1);
              }
            }
          }
        }
      }
      if (endtoendType == "I") {
        for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
          if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.AutomationArchitect)
            this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
          if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.OPERALead)
            this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
        }
      }
      if (endtoendType == "I" || endtoendType == "O") {
        for (var i = 0; i < this.KeyContactsDetails.AlternateContactsList.length; i++) {
          if (this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.TransformationDirector)
            this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
          if (this.KeyContactsDetails.AlternateContactsList[i] != undefined && this.KeyContactsDetails.AlternateContactsList[i].Id == enumAlternativeContacts.ClientExperienceLead)
            this.KeyContactsDetails.AlternateContactsList.splice(i, 1);
        }
      }
      this.sharedService.openAlertPopup('Delete enterpriseid ' + enterpriseid + ' and role ' + roleName + ' Success', window.scrollY, "Ok", false);
      this.KeyContactsDetails.EndToEndAlternateContacts = cloneDeep(this.TempListAlternateContact);
      $('.alternateContactEnterpriseId').val('');
      this.KeyContactsDetails.SelectedContactRoleId = null;
      var pmoLead = Enumerable.from(this.KeyContactsDetails.EndToEndAlternateContacts).where((x: any) => x.AlternateContactRoleTypeId == enumAlternativeContacts.PMOLead).select((s: any) => new Object({ Id: s.AlternateContactRoleTypeId, Name: s.AlternateContactRoleName })).firstOrDefault();
      if (this.KeyContactsDetails.IsSIEnabledContract && pmoLead != undefined)
        this.KeyContactsDetails.IsPMOLeadRoleRequired = false;
      else if (this.KeyContactsDetails.IsSIEnabledContract && pmoLead == undefined)
        this.KeyContactsDetails.IsPMOLeadRoleRequired = true;
    }
  }


  RoleCheck(roleName) {
    if ((roleName == "Global Client Account Lead") || (roleName == "GDN Account Group Lead") || (roleName == "GDN Delivery Group Lead") || (roleName == "Delivery QA Director") || (roleName == "Global Technology Service Lead")) {
      return true;
    }
    else {
      return false;
    }
  }

  showMetroCity(deliveryCenter) {

    const initialState = {
      KeyContactsDetails: this.KeyContactsDetails, deliveryCenter: deliveryCenter, enterpriseId: this.sessionStorage.enterpriseId,
      RoleAccess: this.RoleAccess, E2eDemoGraphics: this.E2eDemoGraphics
    };
    //this.modalRefMetroCityPopup = this.modalService.show(MetroCitiesComponent, {
    //  initialState,
    //  backdrop: "static",
    //  keyboard: false
    //});
    $(".modal-dialog").addClass('modal-lg');

  }

  cancel(form: NgForm) {
    form.resetForm();
    //this.RetainValueForm(form);
    this.LoadKeyContactsTab(false);
    this.isContactsFormDirty = false;
  }


  SetDominantEndToEndDC() {
    if (this.endToEndType == "C") {
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts.forEach((x: any) => {
        if (x.DCLeadingdeliveryCenterId != -1)
          this.DCLeading = x.DeliveryCenterId
      });
    }
    else {
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit.forEach((x: any) => {
        if (x.DCLeadingdeliveryCenterId != -1)
          this.DCLeading = x.DeliveryCenterId
      });
    }
  }

  AccountGroupsChangeforNewRecord(Id) {
    this.DeliveryGroups = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.ParentId == Id).toArray();
    this.DeliveryGroupId = "";
    this.DeliveryUnitId = "";
    this.cdref.detectChanges();
  }
  DeliveryGroupsChangeforNewRecord(Id) {
    this.DeliveryUnits = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.ParentId == Id).toArray();
    this.DeliveryUnitId = "";
    this.cdref.detectChanges();
  }


  DCChange(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index) {
    this.tempData = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit);
    this.tempData.splice(index, 1);
    var dcExist = Enumerable.from(this.tempData).where((x: any) => x.DeliveryCenterId == deliveryCenterId && x.AccountGroupId == accountGroupId && x.DeliveryGroupId == deliveryGroupId && x.DeliveryUnitId == deliveryUnitId).select((s: any) => s).firstOrDefault();
    if (dcExist != undefined) {
      this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryCenterId = this.tempEndToEndDeliveryCenterContactsForOppInit[index].DeliveryCenterId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].AccountGroupId = this.tempEndToEndDeliveryCenterContactsForOppInit[index].AccountGroupId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroupId = this.tempEndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroupId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnitId = this.tempEndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnitId;
    }
    else {
      var changedDC = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryCenterName = changedDC.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].AccountGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnit = "";
    }
    this.tempEndToEndDeliveryCenterContactsForOppInit = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit);
  }

  AccountGroupChange(accountGroupId, gridData, index) {

    gridData.DeliveryGroupId = "";
    gridData.DeliveryUnitId = "";
    if (this.endToEndType == "C") {

      var changedAccountGroup = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.Id == accountGroupId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].AccountGroup = (changedAccountGroup == undefined) ? "" : changedAccountGroup.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnit = "";
    }
    else {
      var changedAccountGroup = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.Id == accountGroupId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].AccountGroup = (changedAccountGroup == undefined) ? "" : changedAccountGroup.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnit = "";
    }

  }
  DeliveryGroupChange(deliveryGroupId, gridData, index) {
    gridData.DeliveryUnitId = "";
    if (this.endToEndType == "C") {
      var changedDeliveryGroup = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.Id == deliveryGroupId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryGroup = (changedDeliveryGroup == undefined) ? "" : changedDeliveryGroup.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnit = "";
    }
    else {
      var changedDeliveryGroup = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.Id == deliveryGroupId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryGroup = (changedDeliveryGroup == undefined) ? "" : changedDeliveryGroup.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnit = "";
    }

  }
  DeliveryUnitChange(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index) {

    if (this.endToEndType == "C") {
      var changedDeliveryUnit = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.Id == deliveryUnitId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnit = (changedDeliveryUnit == undefined) ? "" : changedDeliveryUnit.Name;
      if (deliveryUnitId != "") {
        this.DCChangeContract(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index);
      }
    }
    else {
      var changedDeliveryUnit = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.Id == deliveryUnitId).select((s: any) => s).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit[index].DeliveryUnit = (changedDeliveryUnit == undefined) ? "" : changedDeliveryUnit.Name;
      if (deliveryUnitId != "") {
        this.DCChange(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index);
      }
    }
  }

  DCChangeContract(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, index) {
    this.tempData = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContacts);
    this.tempData.splice(index, 1);
    var dcExist = Enumerable.from(this.tempData).where((x: any) => x.DeliveryCenterId == deliveryCenterId && x.AccountGroupId == accountGroupId && x.DeliveryGroupId == deliveryGroupId && x.DeliveryUnitId == deliveryUnitId).firstOrDefault();
    if (dcExist != undefined) {
      this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryCenterId = this.tempEndToEndDeliveryCenterContacts[index].DeliveryCenterId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].AccountGroupId = this.tempEndToEndDeliveryCenterContacts[index].AccountGroupId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryGroupId = this.tempEndToEndDeliveryCenterContacts[index].DeliveryGroupId;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnitId = this.tempEndToEndDeliveryCenterContacts[index].DeliveryUnitId;
    }
    else {
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DuplicateDCExists = false;
      var changedDC: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).firstOrDefault();
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryCenterName = changedDC.Name;
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].AccountGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryGroup = "";
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts[index].DeliveryUnit = "";
    }
    this.tempEndToEndDeliveryCenterContacts = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContacts);
  }
  ResetAddDominentRow() {

    this.DeliveryLead = "";
    $("#deliveryLead").val('');
    $("#deliveryLead").removeClass("input-blank-error");
    this.KeyContactsDetails.DeliveryCenterId = "";
    this.AccountGroupId = "";
    this.DeliveryGroupId = "";
    this.DeliveryUnitId = "";
    this.IsTableMandatory = false;
  }

  AddDataContract(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, deliveryLead) {
    if (deliveryCenterId == "" || accountGroupId == "" || deliveryGroupId == "" || deliveryUnitId == "") {
      return false;
    }
    //var enterpriseId = $("#deliveryLead").val();
    var enterpriseId = this.DeliveryLead;
    if (enterpriseId?.length > 0) {
      if (cloneDeep($('#hiddenDeliveryLead')).val() != enterpriseId) {
        this.sharedService.openAlertPopup('Please enter valid Enterprise ID', window.scrollY);
        return false;
      }
    } var dcExist = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.DeliveryCenterId == deliveryCenterId && x.AccountGroupId == accountGroupId && x.DeliveryGroupId == deliveryGroupId && x.DeliveryUnitId == deliveryUnitId).select((s: any) => s).firstOrDefault();
    if (dcExist != undefined) {
      this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
      this.ResetAddDominentRow();
      return false;
    }

    var country: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => new Object({ CountryId: s.ParentId })).firstOrDefault();
    var locationData: any = Enumerable.from(this.KeyContactsDetails.LocationHierarchy).where((x: any) => x.Id == country.CountryId).select((s: any) => new Object({ LocationHierarchyId: s.Id, LocationHierarchyName: s.Name })).firstOrDefault();
    var deliveryCenterData: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => new Object({ DeliveryCenterId: s.Id, DeliveryCenterName: s.Name })).firstOrDefault();
    var accountGroupData: any = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.Id == accountGroupId).select((s: any) => new Object({ AccountGroupId: s.Id, AccountGroupName: s.Name })).firstOrDefault();
    var deliveryGroupData: any = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.Id == deliveryGroupId).select((s: any) => new Object({ DeliveryGroupId: s.Id, DeliveryGroupName: s.Name })).firstOrDefault();
    var deliveryUnitData: any = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.Id == deliveryUnitId).select((s: any) => new Object({ DeliveryUnitId: s.Id, DeliveryUnitName: s.Name })).firstOrDefault();
    var DeliveryCenterId = (deliveryCenterData == undefined) ? 0 : deliveryCenterData.DeliveryCenterId;
    var DeliveryCenterName = (deliveryCenterData == undefined) ? "" : deliveryCenterData.DeliveryCenterName;
    var LocationHierarchyId = (locationData == undefined) ? 0 : locationData.LocationHierarchyId;
    var LocationHierarchyName = (locationData == undefined) ? "" : locationData.LocationHierarchyName;
    var AccountGroupId = (accountGroupData == undefined) ? 0 : accountGroupData.AccountGroupId;
    var AccountGroup = (accountGroupData == undefined) ? "" : accountGroupData.AccountGroupName;
    var DeliveryGroupId = (deliveryGroupData == undefined) ? 0 : deliveryGroupData.DeliveryGroupId;
    var DeliveryGroup = (deliveryGroupData == undefined) ? "" : deliveryGroupData.DeliveryGroupName;
    var DeliveryUnitId = (deliveryUnitData == undefined) ? 0 : deliveryUnitData.DeliveryUnitId;
    var DeliveryUnitName = (deliveryUnitData == undefined) ? "" : deliveryUnitData.DeliveryUnitName;
    var gridData = {
      DCLeadingdeliveryCenterId: -1,
      EndToEndDeliveryCenterId: null,
      LedByIndicator: false,
      EffortsContributed : null,
      DcCountryId: LocationHierarchyId,
      DcCountryName: LocationHierarchyName,
      DeliveryCenterId: DeliveryCenterId,
      DeliveryCenterName: DeliveryCenterName,
      DeliveryGroupId: DeliveryGroupId,
      DeliveryGroup: DeliveryGroup,
      AccountGroupId: AccountGroupId,
      AccountGroup: AccountGroup,
      DeliveryUnitId: DeliveryUnitId,
      DeliveryUnit: DeliveryUnitName,
      DeliveryLead: deliveryLead,
    };
    if (this.KeyContactsDetails.EndToEndDeliveryCenterContacts == null) {
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts = [];
    }
    this.KeyContactsDetails.EndToEndDeliveryCenterContacts.push(gridData);
    this.tempEndToEndDeliveryCenterContacts = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContacts);
    this.ResetAddDominentRow();

  }
  AddData(deliveryCenterId, accountGroupId, deliveryGroupId, deliveryUnitId, deliveryLead) {
    if (deliveryCenterId == "" || accountGroupId == "" || deliveryGroupId == "" || deliveryUnitId == "") {
      return false;
    }
    //var enterpriseId = $("#deliveryLead").val();
    var enterpriseId = this.DeliveryLead;
    if (enterpriseId?.length > 0) {
      if ($('#hiddenDeliveryLead').val() != enterpriseId) {
        this.sharedService.openAlertPopup('Please enter valid Enterprise ID', window.scrollY);
        return false;
      }
    }
    var dcExist = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => x.DeliveryCenterId == deliveryCenterId && x.AccountGroupId == accountGroupId && x.DeliveryGroupId == deliveryGroupId && x.DeliveryUnitId == deliveryUnitId).select((s: any) => s).firstOrDefault();
    if (dcExist != undefined) {
      this.sharedService.openAlertPopup("Selected DC already exists", window.scrollY);
      this.ResetAddDominentRow();
      return false;
    }

    var country: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => new Object({ CountryId: s.ParentId })).firstOrDefault();
    var locationData: any = Enumerable.from(this.KeyContactsDetails.LocationHierarchy).where((x: any) => x.Id == country.CountryId).select((s: any) => new Object({ LocationHierarchyId: s.Id, LocationHierarchyName: s.Name })).firstOrDefault();
    var deliveryCenterData: any = Enumerable.from(this.KeyContactsDetails.DC).where((x: any) => x.Id == deliveryCenterId).select((s: any) => new Object({ DeliveryCenterId: s.Id, DeliveryCenterName: s.Name })).firstOrDefault();
    var accountGroupData: any = Enumerable.from(this.KeyContactsDetails.AccountGroups).where((x: any) => x.Id == accountGroupId).select((s: any) => new Object({ AccountGroupId: s.Id, AccountGroupName: s.Name })).firstOrDefault();
    var deliveryGroupData: any = Enumerable.from(this.KeyContactsDetails.DeliveryGroups).where((x: any) => x.Id == deliveryGroupId).select((s: any) => new Object({ DeliveryGroupId: s.Id, DeliveryGroupName: s.Name })).firstOrDefault();
    var deliveryUnitData: any = Enumerable.from(this.KeyContactsDetails.DeliveryUnits).where((x: any) => x.Id == deliveryUnitId).select((s: any) => new Object({ DeliveryUnitId: s.Id, DeliveryUnitName: s.Name })).firstOrDefault();
    var DeliveryCenterId = (deliveryCenterData == undefined) ? 0 : deliveryCenterData.DeliveryCenterId;
    var DeliveryCenterName = (deliveryCenterData == undefined) ? "" : deliveryCenterData.DeliveryCenterName;
    var LocationHierarchyId = (locationData == undefined) ? 0 : locationData.LocationHierarchyId;
    var LocationHierarchyName = (locationData == undefined) ? "" : locationData.LocationHierarchyName;
    var AccountGroupId = (accountGroupData == undefined) ? 0 : accountGroupData.AccountGroupId;
    var AccountGroup = (accountGroupData == undefined) ? "" : accountGroupData.AccountGroupName;
    var DeliveryGroupId = (deliveryGroupData == undefined) ? 0 : deliveryGroupData.DeliveryGroupId;
    var DeliveryGroup = (deliveryGroupData == undefined) ? "" : deliveryGroupData.DeliveryGroupName;
    var DeliveryUnitId = (deliveryUnitData == undefined) ? 0 : deliveryUnitData.DeliveryUnitId;
    var DeliveryUnitName = (deliveryUnitData == undefined) ? "" : deliveryUnitData.DeliveryUnitName;
    var gridData = {
      DCLeadingdeliveryCenterId: -1,
      EndToEndDeliveryCenterId: null,
      LedByIndicator: false,
      EffortsContributed : null,
      DcCountryId: LocationHierarchyId,
      DcCountryName: LocationHierarchyName,
      DeliveryCenterId: DeliveryCenterId,
      DeliveryCenterName: DeliveryCenterName,
      DeliveryGroupId: DeliveryGroupId,
      DeliveryGroup: DeliveryGroup,
      AccountGroupId: AccountGroupId,
      AccountGroup: AccountGroup,
      DeliveryUnitId: DeliveryUnitId,
      DeliveryUnit: DeliveryUnitName,
      DeliveryLead: deliveryLead,
    };
    if (this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit == null) {
      this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit = [];
    }
    this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit.push(gridData);
    this.tempEndToEndDeliveryCenterContactsForOppInit = cloneDeep(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit);
    this.ResetAddDominentRow();

    return true;
  }

  DeleteData(LeadingAGDGDUId, DCLeadingdeliveryCenterId, DeliveryCenterId, DeliveryUnitId, endToEndType, EndtoEndDeliveryCenterId,
    DcCountryId, deliveryLead, accountGroupLead, deliveryGroupLead, deliveryUnitLead) {
    if (this.RoleAccess == "V") {
      return false;
    }

    if(LeadingAGDGDUId != -1){
      this.sharedService.openAlertPopup('You are about to delete the Leading Account Group(AG), Delivery Group(DG) , Delivery Unit(DU) .Please select and save a different Leading AG, DG, DU before deletion.', window.scrollY);
      return false;
    }
    else{
      if(EndtoEndDeliveryCenterId == this.ExistingLeadingAGDGDU && EndtoEndDeliveryCenterId != null){
        this.sharedService.openAlertPopup('Please save your Leading AG, DG, DU selection changes', window.scrollY);
        return false;
      }
    }

    var multipleDC = false;
    if (this.endToEndType == "C") {
      var dcData: any = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContacts).where((x: any) => x.DeliveryCenterId == DeliveryCenterId).select((s: any) => s).toArray();
      if (dcData.length > 1) {
        multipleDC = true;
      }
    }
    else {
      var dcData: any = Enumerable.from(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit).where((x: any) => x.DeliveryCenterId == DeliveryCenterId).select((s: any) => s).toArray();
      if (dcData.length > 1) {
        multipleDC = true;
      }
    }
    if (multipleDC == true) {
      if (DCLeadingdeliveryCenterId == -1) {
        if (this.DCLeading == DcCountryId) {
          var isUnsavedDC = false;
          isUnsavedDC = (EndtoEndDeliveryCenterId == this.ExistingDominantId || EndtoEndDeliveryCenterId == this.CurrentDominantId);

        }
        var results = this.sharedService.openConfirmationPopup('You are about to delete the selected Reporting Portfolio, which will result in deletion of the respective Metro City and Facility details (if added). Please click OK to proceed. Please note that any unsaved changes will also be lost in which case, please cancel to retain those changes before proceeding to delete.', 'OK', 'Cancel', window.scrollY);
        results.content.confirmationResult.subscribe(response => {
          if (!response.result) {
            return false;
          } else {
            if (results) {
              var revokeAccessEnterpriseIdDetails = this.revokeAccessEnterpriseId(deliveryLead, accountGroupLead, deliveryGroupLead, deliveryUnitLead);
              this.demographicService.DeleteDCData(this.endToEndId, DCLeadingdeliveryCenterId, DeliveryCenterId, DeliveryUnitId, endToEndType,
                EndtoEndDeliveryCenterId == null ? 0 : EndtoEndDeliveryCenterId, revokeAccessEnterpriseIdDetails).subscribe(() => {
                  this.LoadKeyContactsTab(false);
                });
            }
          }});
       
      }
    }
    else {
      if (DCLeadingdeliveryCenterId == -1) {
        if (this.DCLeading == DcCountryId) {
          var isUnsavedDC = false;
          if (this.endToEndType == "C") {
            this.KeyContactsDetails.EndToEndDeliveryCenterContacts.forEach((x: any) => {
              if (x.DCLeadingdeliveryCenterId != -1 && this.DCLeading != x.DeliveryCenterId)
                isUnsavedDC = true;
            });
          }
          else {
            this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit.forEach((x: any) => {
              if (x.DCLeadingdeliveryCenterId != -1 && this.DCLeading != x.DeliveryCenterId)
                isUnsavedDC = true;
            });
          }
        }

        var results = this.sharedService.openConfirmationPopup('You are about to delete the selected Reporting Portfolio, which will result in deletion of the respective Metro City and Facility details (if added). Please click OK to proceed. Please note that any unsaved changes will also be lost in which case, please cancel to retain those changes before proceeding to delete.', 'OK', 'Cancel', window.scrollY);
        results.content.confirmationResult.subscribe(response => {
          if (!response.result) {
            return false;
          } else {
        if (results) {
          var revokeAccessEnterpriseIdDetails = this.revokeAccessEnterpriseId(deliveryLead, accountGroupLead, deliveryGroupLead, deliveryUnitLead);
          this.demographicService.DeleteDCData(this.endToEndId, DCLeadingdeliveryCenterId, DeliveryCenterId, DeliveryUnitId, endToEndType,
            EndtoEndDeliveryCenterId == null ? 0 : EndtoEndDeliveryCenterId, revokeAccessEnterpriseIdDetails).subscribe(() => {
              this.LoadKeyContactsTab(false);
            });
        }}
      });
       
      }
    }
  }

  revokeAccessEnterpriseId(deliveryLead, accountGroupLead, deliveryGroupLead, deliveryUnitLead) {
    var enterpriseId = "";
    if (deliveryLead) {
      enterpriseId = deliveryLead;
    }
    if (accountGroupLead) {
      enterpriseId = enterpriseId ? enterpriseId + ',' + accountGroupLead : accountGroupLead;
    }
    if (deliveryGroupLead) {
      enterpriseId = enterpriseId ? enterpriseId + ',' + deliveryGroupLead : deliveryGroupLead;
    }

    if (deliveryUnitLead) {
      enterpriseId = enterpriseId ? enterpriseId + ',' + deliveryUnitLead : deliveryUnitLead;
    }

    return enterpriseId;
  }

  MetroCityInfo(endtoendDeliveryCenterId, portfoio, accountGroup, deliveryGroup, deliveryUnit, dcCountryId) {
  }

  trackByDeliveryCenterId(index: number, item: any): any {
    return item.DeliveryCenterId;
  }

  trackById(index: number, item: any): any {
    return item.Id;
  }
  trackByIndex(index: number, item: any): number {
    return index;
  }

  RetainValueForm(form: NgForm) {
    form.controls.deliveryunit.patchValue("");
    form.controls.deliverygroup.patchValue("");
    form.controls.accountgroup.patchValue("");
    form.controls.deliverycenter.patchValue("");
  }

  paginationClick(pageno: number) {
    this.startIndex = (pageno) * this.pageSize;
    this.endIndex = (pageno) * this.pageSize + this.pageSize;
    this.curPage = pageno;
    if (this.endToEndType == 'C')
      this.slicedGridData = this.KeyContactsDetails.EndToEndDeliveryCenterContacts.slice(this.startIndex, this.endIndex);
    else
      this.slicedGridData = this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit.slice(this.startIndex, this.endIndex);
    this.noOfPages = this.numberOfPages();
    setTimeout(function () {
      window.TooltipFunc();
    }, 0);
  }
  numberOfPages() {
    if (this.endToEndType == 'C')
      return this.KeyContactsDetails.EndToEndDeliveryCenterContacts != undefined && this.KeyContactsDetails.EndToEndDeliveryCenterContacts != null ? Math.ceil(this.KeyContactsDetails.EndToEndDeliveryCenterContacts.length / this.pageSize) : 0;
    else
      return this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit != undefined && this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit != null ? Math.ceil(this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit.length / this.pageSize) : 0;
  }
 
  LeadingAGDGDUChange(data) {
    if (this.endToEndType == "C") {
      this.KeyContactsDetails.EndToEndDeliveryCenterContacts.forEach((x: any) => {
      var lead = (x.DcCountryId == data.DcCountryId && x.DeliveryCenterId == data.DeliveryCenterId && x.AccountGroupId == data.AccountGroupId && x.DeliveryGroupId == data.DeliveryGroupId && x.DeliveryUnitId == data.DeliveryUnitId);
      if (lead == false) {
        x.LeadingAGDGDUId = -1;
        x.LeadingAGDGDU = false;
      }
      else{
        x.LeadingAGDGDU = true;
        x.LeadingAGDGDUId = x.EndToEndDeliveryCenterId;
      }
    });
      }
      else{
        this.KeyContactsDetails.EndToEndDeliveryCenterContactsForOppInit.forEach((x: any) => {
      var lead = (x.DcCountryId == data.DcCountryId && x.DeliveryCenterId == data.DeliveryCenterId && x.AccountGroupId == data.AccountGroupId && x.DeliveryGroupId == data.DeliveryGroupId && x.DeliveryUnitId == data.DeliveryUnitId);
      if (lead == false) {
        x.LeadingAGDGDUId = -1;
        x.LeadingAGDGDU = false;
      }
      else{
        x.LeadingAGDGDU = true;
        x.LeadingAGDGDUId = x.EndToEndDeliveryCenterId;

      }
    });
      }
  }

}
